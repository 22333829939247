import { createContext, useContext } from "react";

import type { Organization } from "@tudigo-monorepo/core-tudigo-api-models";

import type { TaxResidenceAddressFormData } from "../components/tax-residence-address/tax-residence-address-form";

type TaxResidenceContextValue = {
  canEdit: boolean;
  organization: Organization | null;
  taxResidence: TaxResidenceAddressFormData;
  isLoading: boolean;
  isValid: boolean;
  onUpdated?: () => void;
};

const TaxResidenceContext = createContext<TaxResidenceContextValue | null>(
  null,
);

function useTaxResidence() {
  const context = useContext(TaxResidenceContext);

  if (context === null) {
    throw new Error(
      "useTaxResidence must be used within a TaxResidenceProvider",
    );
  }

  return context;
}

export { type TaxResidenceContextValue, TaxResidenceContext, useTaxResidence };
