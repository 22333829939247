import { useEffect } from "react";

import type { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { useGetUserByIdQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import useAuthStore from "@tudigo-monorepo/web-tudigo-auth-store";

import { useSessionStore } from "../shared-external/providers/session/session-store";
import { useSessionUserId } from "./use-session-user-id";

export function useSetupSessionUser(callback: (user: User) => void) {
  const userId = useSessionUserId();
  const accessToken = useAuthStore((state) => state.accessToken);

  const setUser = useSessionStore((state) => state.setUser);
  const setError = useSessionStore((state) => state.setError);

  const {
    data: userResponse,
    error: userError,
    ...userQuery
  } = useGetUserByIdQuery({ userId }, { enabled: !!accessToken });

  useEffect(() => {
    if (userError) {
      setError(userError);
      return;
    }

    if (userResponse) {
      setUser(userResponse);

      if (callback) {
        callback(userResponse);
      }
    }
  }, [userError, userResponse, setError, setUser, callback]);

  return { userResponse, userError, ...userQuery };
}
