import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import type { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import type {
  Configuration,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

export type SessionStore = {
  user: User | null;
  isLoading: boolean;
  error: TudigoError | null;
  configuration: Configuration | null;
};

type SessionActions = {
  setUser: (user: User | null) => void;
  setIsLoading: (loading: boolean) => void;
  setError: (error: TudigoError | null) => void;
  setConfiguration: (configuration: Configuration | null) => void;
  reset: () => void;
};

const initialStore: SessionStore = {
  user: null,
  isLoading: false,
  error: null,
  configuration: null,
};

export const useSessionStore = create<SessionStore & SessionActions>()(
  persist(
    (set) => ({
      ...initialStore,
      setUser: (user) => set(() => ({ user })),
      setConfiguration: (configuration) => set(() => ({ configuration })),
      setIsLoading: (isLoading) => set(() => ({ isLoading })),
      setError: (error) => set(() => ({ error })),
      reset: () => set(initialStore),
    }),
    {
      name: "session-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
