import { PageEnum, SpaceEnum, SpaceType } from "./space-types";

export const pathsByPagesAndSpaces = {
  [PageEnum.HOME]: {
    [SpaceEnum.WMA]: "/wma/projects",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/projects",
    [SpaceEnum.INVESTOR]: "/",
    [SpaceEnum.ADMIN]: "/dashboard",
  },
  [PageEnum.DASHBOARD]: {
    [SpaceEnum.WMA]: "/wma/dashboard",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/dashboard",
    [SpaceEnum.INVESTOR]: "/my-account/dashboard",
    [SpaceEnum.ADMIN]: "/dashboard",
  },
  [PageEnum.PROJECTS]: {
    [SpaceEnum.WMA]: "/wma/projects",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/dashboard",
    [SpaceEnum.INVESTOR]: "/",
    [SpaceEnum.ADMIN]: "/projects",
  },
  [PageEnum.PROJECT]: {
    [SpaceEnum.WMA]: "/wma/projects/:projectId",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader/projects/:projectId",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/projects/:projectId",
    [SpaceEnum.INVESTOR]: "/projects/:projectId",
    [SpaceEnum.ADMIN]: "/projects/:projectId",
  },
  [PageEnum.ACCOUNTS]: {
    [SpaceEnum.WMA]: "/wma/profile-and-accounts",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader/profile-and-accounts",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/profile-and-accounts",
    [SpaceEnum.INVESTOR]: "/profile-and-accounts",
    [SpaceEnum.ADMIN]: "/profile-and-accounts",
  },
  [PageEnum.SETTINGS]: {
    [SpaceEnum.WMA]: "/wma/my-settings",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader/my-settings",
    [SpaceEnum.PROJECT_HOLDER]: "/project-holder/my-settings",
    [SpaceEnum.INVESTOR]: "/my-settings",
    [SpaceEnum.ADMIN]: "/my-settings",
  },
  [PageEnum.ORGANIZATIONS]: {
    [SpaceEnum.WMA]: "/wma/profile-and-accounts/organizations",
    [SpaceEnum.CIRCLE_LEADER]:
      "/club-leader/profile-and-accounts/organizations",
    [SpaceEnum.PROJECT_HOLDER]:
      "/project-holder/profile-and-accounts/organizations",
    [SpaceEnum.INVESTOR]: "/profile-and-accounts/organizations",
    [SpaceEnum.ADMIN]: "/organizations",
  },
  [PageEnum.ORGANIZATION_KYC_VIEWER]: {
    [SpaceEnum.WMA]: "/wma/profile-and-accounts/organizations/:organizationId",
    [SpaceEnum.CIRCLE_LEADER]:
      "/club-leader/profile-and-accounts/organizations/:organizationId",
    [SpaceEnum.PROJECT_HOLDER]:
      "/project-holder/profile-and-accounts/organizations/:organizationId",
    [SpaceEnum.INVESTOR]: "/profile-and-accounts/organizations/:organizationId",
    [SpaceEnum.ADMIN]: "/organizations/:organizationId",
  },
  [PageEnum.ORGANIZATION_KYC_PEA_VIEWER]: {
    [SpaceEnum.WMA]:
      "/wma/profile-and-accounts/organizations/:organizationId/type/:type",
    [SpaceEnum.CIRCLE_LEADER]:
      "/club-leader/profile-and-accounts/organizations/:organizationId/type/:type",
    [SpaceEnum.PROJECT_HOLDER]:
      "/project-holder/profile-and-accounts/organizations/:organizationId/type/:type",
    [SpaceEnum.INVESTOR]:
      "/profile-and-accounts/organizations/:organizationId/type/:type",
    [SpaceEnum.ADMIN]: "/organizations/:organizationId/type/:type",
  },
  [PageEnum.ORGANIZATION_KYC_FUNNEL]: {
    [SpaceEnum.WMA]: "/wma/organization/:organizationId/kyc",
    [SpaceEnum.CIRCLE_LEADER]: "/club-leader/organization/:organizationId/kyc",
    [SpaceEnum.PROJECT_HOLDER]:
      "/project-holder/organization/:organizationId/kyc",
    [SpaceEnum.INVESTOR]: "/organization/:organizationId/kyc",
    [SpaceEnum.ADMIN]: "/organization/:organizationId/kyc",
  },
};

export const getPagePathsBySpace = (space: SpaceType) => {
  const pagesPaths = {} as Record<PageEnum, string>;
  Object.values(PageEnum).forEach((page) => {
    if (!pathsByPagesAndSpaces[page as PageEnum]) {
      throw new Error(`Missing path for page ${page}`);
    }
    if (!pathsByPagesAndSpaces[page as PageEnum][space]) {
      throw new Error(`Missing path for page ${page} and space ${space}`);
    }
    pagesPaths[page as PageEnum] =
      pathsByPagesAndSpaces[page as PageEnum][space];
  });

  return pagesPaths;
};
