// -------------- Qualification (Investor Profile) --------
export const UI_INVESTOR_PROFILE_STARTED = "ui.investor_profile.started";

export const UI_INVESTOR_PROFILE_STEP_PAGE_VIEW =
  "ui.investor_profile_step_page.view";

export const UI_INVESTOR_PROFILE_STEP_COMPLETED_VIEW =
  "ui.investor_profile_step.completed";

// -------------- Investment -----------------------------
export const UI_INVESTMENT_STARTED = "ui.investment.started";

export const UI_INVESTMENT_CONFIRMATION_PAGE_VIEW =
  "ui.investment_confirmation_page.view";

// -------------- Projects Comparator -----------------------------
export const UI_PROJECTS_COMPARATOR_STARTED = "ui.comparator.started";

export const UI_PROJECTS_COMPARATOR_SELECTION_PROJECT_ADDED =
  "ui.comparator_cart_project.added";

export const UI_PROJECTS_COMPARATOR_SELECTION_PROJECT_DELETED =
  "ui.comparator_cart_project.deleted";

export const UI_PROJECTS_COMPARATOR_MODAL_OPENED = "ui.comparator_page.view";

export const UI_PROJECTS_COMPARATOR_MODAL_CLOSED = "ui.comparator.closed";

export const UI_PROJECTS_COMPARATOR_MODAL_PROJECT_ADDED =
  "ui.comparator_project.added";

export const UI_PROJECTS_COMPARATOR_MODAL_PROJECT_DELETED =
  "ui.comparator_project.deleted";

export const UI_PROJECTS_COMPARATOR_SIMULATOR_AMOUNT_CHANGED =
  "ui.comparator_amount.changed";

export const UI_PROJECTS_COMPARATOR_PROJECT_LINK_CLICKED =
  "ui.comparator_project.clic";

export const UI_PROJECTS_COMPARATOR_REPAYMENT_TABLE_VIEW =
  "ui.comparator_project_repayment_table.view";

export const UI_PROJECTS_COMPARATOR_SHARE_CLICKED = "ui.comparator_share.clic";

export const UI_PROJECTS_COMPARATOR_SHARE_COPY_CLICKED =
  "ui.comparator_share_link.clic";
