import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { DateInput, type DateInputProps } from "./date-input";

interface DateInputConnectedProps {
  name: string;
  label?: string;
  placeholder?: string;
  mode?: "single" | "range";
  description?: string;
  required?: boolean;
  className?: string;
  onDateChange?: DateInputProps["onChange"];
}

export function DateInputConnected({
  name,
  label,
  placeholder,
  mode = "single",
  description,
  required,
  className,
  onDateChange,
}: DateInputConnectedProps) {
  const form = useFormContext();

  if (!form) {
    throw new Error("DateInputConnected must be used within a Form component");
  }

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className="flex w-full flex-col">
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <FormControl>
            <DateInput
              className={className}
              placeholder={placeholder}
              mode={mode}
              value={field.value}
              onChange={(date) => {
                field.onChange(date);
                if (onDateChange) {
                  onDateChange(date);
                }
              }}
            />
          </FormControl>
          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}

export default DateInputConnected;
