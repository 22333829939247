import * as React from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ButtonRebrand } from "../../../../rebrand/ui/button-rebrand/button-rebrand";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../popover/popover";
import { Calendar } from "./calendar";

export type DateInputProps = {
  mode?: "single" | "range";
  value?: Date | DateRange;
  onChange?: (value: Date | DateRange | undefined) => void;
  placeholder?: string;
  className?: string;
  required?: boolean;
};

function formatRangeDate(date: DateRange) {
  if (!date) {
    return;
  }

  return (
    date.from && (
      <span>
        {format(date.from, "PPP", { locale: fr })} -{" "}
        {date.to ? format(date.to, "PPP", { locale: fr }) : "En cours"}
      </span>
    )
  );
}

export function DateInput({
  value,
  onChange,
  placeholder,
  className,
  mode = "single",
}: DateInputProps) {
  const handleOnChangeRange = (dateRange: DateRange | undefined) => {
    if (onChange) {
      onChange(dateRange);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <ButtonRebrand
          variant="outline"
          className={cn(
            "justify-start text-left font-normal",
            "h-[40px] w-full",
            !value && "text-muted-foreground",
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />

          {!value && <span>{placeholder || "Selectionnez une date"}</span>}

          {mode === "single" &&
            value &&
            format(value as Date, "PPP", { locale: fr })}

          {mode === "range" && formatRangeDate(value as DateRange)}
        </ButtonRebrand>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        {mode === "single" && (
          <Calendar
            mode="single"
            selected={value as Date}
            onSelect={(date) => onChange && onChange(date)}
            initialFocus
          />
        )}
        {mode === "range" && (
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={(value as DateRange)?.from}
            selected={value as DateRange}
            onSelect={handleOnChangeRange}
            numberOfMonths={2}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
