import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { LoginButton, SignupButton } from "@tudigo-monorepo/web-feature-login";

export function NavbarAuthBlock() {
  const { isConnected } = useUserBusiness();

  if (isConnected) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch gap-4 md:flex-row-reverse md:justify-end">
      <SignupButton />
      <LoginButton />
    </div>
  );
}
