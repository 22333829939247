import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Configuration } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import { getQueryKey } from "../../query-key-factory";

const getConfigurationRequest = async () => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.configuration.getConfiguration();

  return response.data;
};

export const useGetConfigurationQuery = (
  options?: Omit<
    UseQueryOptions<TudigoResponse<Configuration>, TudigoError>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery({
    queryKey: getQueryKey(QUERY_KEYS.CONFIGURATION),
    queryFn: getConfigurationRequest,
    ...options,
  });
};
