import type {
  Document,
  News,
  NewsType,
  Project,
  ProjectTransitionStatus,
  Video,
} from "@tudigo-monorepo/core-tudigo-api-models";

import {
  ContentType,
  type Request,
  type RequestParams,
  type TudigoResponse,
} from "../api";

export interface PostProjectTransitionStatusParams {
  pathParams: {
    projectId: string;
    transitionStatus: ProjectTransitionStatus;
  };
  data: { scheduledStartDate: Project["scheduledStartDate"] };
  query?: { fields?: string };
}

export interface EnablePeaPortfoliosForProjectParams {
  pathParams: {
    projectId: string;
  };
  query?: { fields?: string };
}

export type CreateProjectNewsRequestBody = {
  title: string;
  project: {
    id: Project["id"];
  };
  description?: string;
  type: NewsType;
  document?: Document | null;
  video?: Video;
  shouldSendMail?: boolean;
  shouldSendNotification?: boolean;
  publishedAt?: Date | null;
};

export interface UpdateProjectNewsRequestBody {
  id: string;
  title?: string;
  description?: string;
  type: NewsType;
  document?: Document;
  video?: Video;
  publishedAt?: Date | null;
}

export interface DeleteProjectNewsRequestBody {
  newsId: string;
}

export interface GenerateTitleDeedsRequestBody {
  projectId: string;
  shouldSendEmail: boolean;
  bankId?: string;
}

export interface DownloadTitleDeedsRequestBody {
  projectId: string;
  bankId?: string;
}

export interface DownloadSubscriptionFormsRequestBody {
  projectId: string;
}

export const projectsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getProjectById
   * @summary get project by id, id can be replaced with 'me'
   * @request GET:/api/v1/projects/:projectId
   * @secure
   */
  getProjectById: (
    projectId: Project["id"] | "me",
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects/${projectId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getProjects
   * @summary get projects
   * @request GET:/api/v1/projects
   * @secure
   */
  getProjects: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project[]>, void>({
      path: `/api/v1/projects`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getProjectNews
   * @summary get project news
   * @request GET:/api/v1/projects/:projectId/news
   * @secure
   */
  getProjectNews: (
    projectId: Project["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<News[]>, void>({
      path: `/api/v1/projects/${projectId}/news`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name updateProject
   * @summary updateProject, id can be replaced with 'me'
   * @request PUT:/api/v1/projects/:projectId
   * @secure
   */
  updateProject: (
    projectId: Project["id"] | "me",
    body: Project,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects/${projectId}`,
      method: "PUT",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name createProject
   * @summary createProject
   * @request PUT:/api/v1/projects
   * @secure
   */
  createProject: (
    body: Project,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  patchProject: (
    projectId: Project["id"],
    body: Partial<Project>,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects/${projectId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name postProjectTransitionStatus
   * @summary updateProject, id can be replaced with 'me'
   * @request POST:/api/v1/projects/:projectId/transition
   * @secure
   */
  postProjectTransitionStatus: (
    data: PostProjectTransitionStatusParams,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects/${data.pathParams.projectId}/${data.pathParams.transitionStatus}`,
      method: "POST",
      body: data.data,
      query: data.query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  enablePeaPortfoliosForProject: (
    data: EnablePeaPortfoliosForProjectParams,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Project>, void>({
      path: `/api/v1/projects/${data.pathParams.projectId}/enable-pea-portfolios-request`,
      method: "POST",
      body: {},
      query: {},
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createProjectNews: (
    body: CreateProjectNewsRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<News>, void>({
      path: `/api/v1/news`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateProjectNews: (
    newsId: string,
    body: UpdateProjectNewsRequestBody,
    query?: { fields?: string },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<News>, void>({
      path: `/api/v1/news/${newsId}`,
      method: "PUT",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  deleteProjectNews: (newsId: string) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/news/${newsId}`,
      method: "DELETE",
      secure: true,
    }),

  generateProjectIncomeTaxCertificates: (
    projectId: string,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<string>, void>({
      path: `/api/v1/projects/${projectId}/income-tax-certificates`,
      method: "POST",
      body: {},
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  generateProjectTitleDeeds: (
    body: GenerateTitleDeedsRequestBody,
    query?: { fields?: string },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: "/api/v1/title-deeds",
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  downloadProjectTitleDeeds: (
    body: DownloadTitleDeedsRequestBody,
    query?: { fields?: string },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<any>, void>({
      ...params,
      path: "/api/v1/title-deeds/zip",
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "blob",
    }),

  downloadProjectSubscriptionForms: (
    body: DownloadSubscriptionFormsRequestBody,
    query?: { fields?: string },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<any>, void>({
      ...params,
      path: "/api/v1/subscription-forms/zip",
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      format: "blob",
    }),

  generateEmptySubscriptionForm: (
    projectId: string,
    params: RequestParams = {},
  ) =>
    request<Blob>({
      ...params,
      path: `/api/v1/projects/${projectId}/empty-subscription-form`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      format: "blob",
    }),
});
