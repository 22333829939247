import type {
  ConfigurationFeatureName,
  FeatureConfig,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { useSessionStore } from "../providers/session/session-store";

export function useFeatureConfiguration(name: ConfigurationFeatureName) {
  const config: FeatureConfig = { enabled: false, version: 0 };
  const configuration = useSessionStore((state) => state.configuration);
  const configByName = configuration?.featuresConfigurations?.[name];

  return !configByName ? config : configByName;
}
