import { useContext, useEffect, type ReactNode } from "react";

import { ContextIntl } from "@tudigo-monorepo/core-tudigo-translations";
import useAuthStore from "@tudigo-monorepo/web-tudigo-auth-store";

import { useSetupAnalytics } from "../../../hooks/use-setup-analytics";
import { useSetupSessionConfiguration } from "../../../hooks/use-setup-session-configuration";
import { useSetupSessionUser } from "../../../hooks/use-setup-session-user";
import { useSessionStore } from "./session-store";

export function SessionProvider({ children }: { children: ReactNode }) {
  const accessToken = useAuthStore((state) => state.accessToken);
  const resetSession = useSessionStore((state) => state.reset);
  const setIsLoading = useSessionStore((state) => state.setIsLoading);

  const setupAnalytics = useSetupAnalytics();
  const { selectLanguage, locale } = useContext(ContextIntl);

  const { isFetching: isLoadingUser, refetch: refetchUser } =
    useSetupSessionUser((user) => {
      setupAnalytics(user);
      if (user.locale && user.locale !== locale) {
        selectLanguage(user.locale);
      }
    });

  const { isFetching: isLoadingConfig, refetch: refetchConfig } =
    useSetupSessionConfiguration();

  const isLoadingSession = isLoadingUser || isLoadingConfig;

  useEffect(() => {
    setIsLoading(isLoadingSession);
  }, [isLoadingSession, setIsLoading]);

  useEffect(() => {
    if (accessToken) {
      refetchUser();
    } else {
      resetSession();
    }
    refetchConfig();
  }, [accessToken, refetchUser, refetchConfig, resetSession]);

  return children;
}
