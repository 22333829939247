import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import type {
  ComparatorConfig,
  ComparatorMode,
  ComparatorUrlState,
} from "../utils/type-defs";

const initialState = {
  mode: null,
  selection: null,
  amount: null,
};

export function useComparatorUrlState<T>(params: {
  config: ComparatorConfig<T>;
  onChange?: (state: ComparatorUrlState) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlState = useRef<ComparatorUrlState>(initialState);

  const getParams = useCallback(
    (name: keyof ComparatorUrlState): string[] | null => {
      return searchParams.get(`comparator-${name}`)?.split(",") ?? null;
    },
    [searchParams],
  );

  const setParams = useCallback(
    (name: keyof ComparatorUrlState, value: Array<string | number>): void => {
      setSearchParams((prevParams) => {
        if (!value.length) {
          prevParams.delete(`comparator-${name}`);
        } else {
          prevParams.set(`comparator-${name}`, value.join(","));
        }
        return prevParams;
      });
    },
    [setSearchParams],
  );

  const getState = useCallback(() => {
    const mode = getParams("mode")?.[0] as ComparatorMode;
    const selection = getParams("selection");
    const amount = getParams("amount")?.[0]
      ? Number(getParams("amount")?.[0])
      : null;
    let state: ComparatorUrlState = initialState;
    if (mode) state = { ...state, mode };
    if (selection) state = { ...state, selection };
    if (!!amount && mode === "comparison") {
      state = { ...state, amount };
    }
    return state;
  }, [getParams]);

  const setState = useCallback(
    (
      subscribe:
        | ComparatorUrlState
        | ((state: ComparatorUrlState) => ComparatorUrlState),
    ) => {
      const state = getState();
      const newState =
        subscribe instanceof Function ? subscribe(state) : subscribe;
      if (!Object.is(newState.mode, state.mode)) {
        setParams("mode", newState.mode ? [newState.mode] : []);
      }
      if (!Object.is(newState.selection, state.selection)) {
        setParams("selection", newState.selection ?? []);
      }
      if (!Object.is(newState.amount, state.amount)) {
        setParams("amount", newState.amount ? [newState.amount] : []);
      }
      Object.assign(urlState.current, newState);
      if (params.onChange) params.onChange(newState);
    },
    [getState, setParams, params.onChange],
  );

  useEffect(() => {
    const state = getState();
    if (!Object.is(JSON.stringify(urlState.current), JSON.stringify(state))) {
      setState(state);
    }
  }, [getState, setState]);

  useLayoutEffect(() => {
    const state = getState();
    if (state.mode === "selection") {
      if (state.amount !== null) {
        // Delete simulator amount from URL if present
        setState((state) => ({ ...state, amount: null }));
      }
    } else if (state.mode === "comparison") {
      if (state.amount === null) {
        // Append simulator amount to URL if not set yet
        setState((state) => ({
          ...state,
          amount: params.config.defaultSimulatorAmount,
        }));
      }
    } else {
      return;
    }
  }, [getState, params.config.defaultSimulatorAmount]);

  return {
    urlState: urlState.current,
    setUrlState: setState,
  };
}
