import { InternalKycStatus, mockOrganizations } from "../organizations";
import { Project, TaxExemptionMechanicsEnum } from "./models";

export const mockProjects: Project[] = [
  {
    id: "project-2",
    finishedAt: null,
    projectAgreementAndSecurityContract: null,
    isReferenced: true,
    marketingSettings: {
      simulatorVisibility: "visible",
      flag: "new",
      gaugeVisibility: "visible",
      goalVisibility: "visible",
      addedAmountInCents: 0,
      addedInvestorCount: 0,
    },
    myOngoingInvestment: null,
    content: {
      id: "251f603c-c6a2-4ec9-aded-19028cf0bb1d",
      locale: "en",
      version: 1,
      name: "Super project",
      cover: null,
      founders: "John Doe, Jane Smith",
      impact: "Impact environnemental positif",
      technologyAndInnovation: "IA & Machine Learning",
      reasonsForFundraising: "Expansion internationale",
      exitAndGuarantees: "Exit prévu dans 5 ans",
      coInvestors: "Business Angels & VCs",
      tudigoAnalysis: "Analyse financière détaillée",
      shortDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia.",
      shortPresentation: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
      ],
      description: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      modalities: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      writer: {
        id: "poet-id",
        firstName: "Poet",
        lastName: "Doe",
        profileImage: null,
      },
    },
    status: "started",
    mechanic: "shares",
    sharesMechanicSettings: {
      unitPriceInCents: 300,
      minimumTicketAmountInCents: 1000 * 100,
      preOperationValuation: 1000000,
      sharesCount: 10000,
      targetedYield: {
        minInPercent: 300,
        mediumInPercent: 400,
        maxInPercent: 500,
      },
      buyPack: true,
      buyPackPeriodInMonth: 36,
      potentialReturnPeriodInMonth: 36,
      exitHorizon: {
        minInMonths: 24,
        maxInMonths: 36,
      },
      internalNoDecimalRateOfReturn: 4,
      internalRateOfReturn: 36,
      carriedNoDecimalRate: 0,
      entryFeesNoDecimalRate: 0,
      managementFeesNoDecimalRate: 0,
    },
    goal: {
      minInCents: 1_000_000 * 100,
      maxInCents: 1_500_000 * 100,
    },
    scheduledStartDate: "2023-11-27T20:10:00+00:00",
    scheduledEndDate: null,
    maturity: {
      name: "Seeding",
      image: null,
      status: "enable",
      code: "seeding",
    },
    startedAt: "2023-11-27T20:10:00+00:00",
    categories: [
      {
        name: "Super cat",
        image: null,
        status: "enable",
        code: "cat_1",
      },
    ],
    tags: [
      {
        name: "Super tag",
        image: null,
        status: "enable",
        code: "tag_1",
      },
    ],
    taxExemptionMechanics: [
      TaxExemptionMechanicsEnum.PEA,
      TaxExemptionMechanicsEnum.PEA_PME,
    ],
    impactScoreInPercent: 3,
    currency: "eur",
    holderOrganization: mockOrganizations[0],
    vehicleOrganization: mockOrganizations[0],
    fundraisingProgressMinGoal: 50,
    fundraisingProgressMaxGoal: 0,
    totalAmountCollectedInCents: 500_000 * 100,
    totalInvestorsCount: 10,
    projectDocuments: [],
    commitmentLetterSigningEnabled: null,
    riskInPercent: null,
    information: {
      foundedYear: 2020,
      location: {
        city: "Paris",
        country: "FR",
      },
      preMoneyValuationInCents: 1300000,
      roundTableAmountInCents: 150000,
      revenueLastYearInCents: 210000,
      revenueForecastForCurrentYearInCents: 260000,
      ebitdaRunRateInCents: 12700,
      ebitdaForecastForCurrentYearInCents: 13400,
    },
  },
  {
    id: "project-1",
    finishedAt: null,
    isReferenced: true,
    marketingSettings: {
      flag: "new",
      simulatorVisibility: "visible",
      gaugeVisibility: "visible",
      goalVisibility: "visible",
      addedAmountInCents: 0,
      addedInvestorCount: 0,
    },
    myOngoingInvestment: null,
    content: {
      id: "b513410d-0689-4aa4-ab58-8fbb350c9e48",
      locale: "en",
      version: 1,
      name: "Super project",
      cover: null,
      founders: "Marie Martin, Pierre Dubois",
      impact: "Impact social positif",
      technologyAndInnovation: "Blockchain & Web3",
      reasonsForFundraising: "R&D et recrutement",
      exitAndGuarantees: "Exit prévu dans 4 ans",
      coInvestors: "Family Office & Business Angels",
      tudigoAnalysis: "Analyse financière approfondie",
      shortDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia.",
      shortPresentation: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
      ],
      description: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      modalities: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      writer: {
        id: "poet-id",
        firstName: "Poet",
        lastName: "Doe",
        profileImage: null,
      },
    },
    status: "started",
    mechanic: "bonds",
    bondsMechanicSettings: {
      isConvertible: false,
      unitPriceInCents: 300,
      minimumTicketAmountInCents: 900,
      repaymentStartDate: "2023-11-27T20:30:00+00:00",
      repaymentType: "at_maturity",
      repaymentFrequency: "monthly",
      annualInterestNoDecimalRate: 400,
      nonConversionBonusNoDecimalRate: 200,
      penaltyRate: 1,
      durationInMonth: 36,
      conversionHorizonInMonth: null,
      penaltyNoDecimalRate: 1,
      entryFeesNoDecimalRate: 0,
      managementFeesNoDecimalRate: 0,
      annualInterestPercent: 0,
      repaymentScheduleVisibility: "hidden",
      taxesApplied: false,
    },
    projectAgreementAndSecurityContract: null,
    goal: {
      minInCents: 400000,
      maxInCents: 450000,
    },
    maturity: {
      name: "Seeding",
      image: null,
      status: "enable",
      code: "seeding",
    },
    scheduledStartDate: "2023-11-27T20:10:00+00:00",
    scheduledEndDate: null,
    startedAt: "2023-11-27T20:10:00+00:00",
    categories: [
      {
        name: "Super cat",
        image: null,
        status: "enable",
        code: "cat_1",
      },
    ],
    tags: [
      {
        name: "Super tag",
        image: null,
        status: "enable",
        code: "tag_1",
      },
    ],
    taxExemptionMechanics: [TaxExemptionMechanicsEnum.PEA],
    impactScoreInPercent: 3,
    currency: "eur",
    holderOrganization: mockOrganizations[0],
    vehicleOrganization: {
      readyToSubscribe: true,
      businessCategories: null,
      organizationBankAccounts: [],
      ultimateBeneficialOwners: [],
      socialLinks: {},
      equitySavingPlan: null,
      smallBusinessEquitySavingPlan: null,
      documents: [],
      pmsAccount: null,
      id: "ref_organization_id",
      name: "Super org",
      website: "https://superorg.com",
      logo: null,
      companyInformation: null,
      type: "company",
      individualInformation: null,
      persons: [],
      internalKycStatus: InternalKycStatus.APPROVED,
      members: [],
      organizationRepresentative: {
        id: "1",
        isUltimateBeneficialOwner: true,
        isOrganizationRepresentative: true,
        firstName: "John",
        lastName: "Doe",
        email: "test@tst.com",
        phone: "0123456789",
        position: "CEO",
        birthDate: "1990-01-01",
        gender: "male",
        nationality: "FR",
        documents: [],
        user: null,
        birthLocation: {
          street1: null,
          street2: null,
          postalCode: null,
          city: "Paris",
          country: "FR",
          state: null,
          region: null,
          isVerified: true,
          email: null,
          firstName: null,
          lastName: null,
          id: null,
          name: null,
          phoneNumber: null,
          formatted: "",
        },
        sharesCount: 100,
        address: {
          street1: "1 rue de la Paix",
          street2: null,
          postalCode: "75000",
          city: "Paris",
          country: "FR",
          state: null,
          region: null,
          isVerified: true,
          email: null,
          firstName: null,
          lastName: null,
          id: null,
          name: null,
          phoneNumber: null,
          formatted: "",
        },
      },
    },
    fundraisingProgressMinGoal: 47,
    fundraisingProgressMaxGoal: 47,
    totalAmountCollectedInCents: 187500,
    totalInvestorsCount: 10,
    projectDocuments: [],
    commitmentLetterSigningEnabled: null,
    riskInPercent: null,
    information: {
      foundedYear: 2019,
      location: {
        city: "Lyon",
        country: "FR",
      },
      preMoneyValuationInCents: 980000,
      roundTableAmountInCents: 120000,
      revenueLastYearInCents: 180000,
      revenueForecastForCurrentYearInCents: 220000,
      ebitdaRunRateInCents: 9800,
      ebitdaForecastForCurrentYearInCents: 11200,
    },
  },
  {
    id: "project-3",
    finishedAt: null,
    isReferenced: true,
    marketingSettings: {
      flag: "new",
      gaugeVisibility: "visible",
      goalVisibility: "visible",
      simulatorVisibility: "visible",
      addedAmountInCents: 0,
      addedInvestorCount: 0,
    },
    myOngoingInvestment: null,
    content: {
      id: "b513410d-0689-4aa4-ab58-8fbb350c9e48",
      founders: "Sophie Bernard, Thomas Petit",
      impact: "Impact économique local",
      technologyAndInnovation: "IoT & Smart City",
      reasonsForFundraising: "Développement commercial",
      exitAndGuarantees: "Exit prévu dans 6 ans",
      coInvestors: "Investisseurs industriels",
      tudigoAnalysis: "Analyse financière complète",
      shortDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nunc nec lacinia.",
      shortPresentation: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
      ],
      locale: "en",
      version: 1,
      name: "Super project",
      cover: null,
      description: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      modalities: [
        {
          id: "1",
          type: "paragraph",
          data: {
            text: "cool",
          },
        },
        {
          id: "2",
          type: "images",
          data: {
            type: "single",
            images: [],
            image: {
              id: null,
              url: "https://picsum.photos/500/200",
              base64: null,
              originalFilename: null,
              resizedImages: null,
            },
            caption: null,
            withBorder: null,
            withBackground: null,
            stretched: null,
          },
        },
      ],
      writer: {
        id: "poet-id",
        firstName: "Poet",
        lastName: "Doe",
        profileImage: null,
      },
    },
    status: "started",
    mechanic: "bonds",
    bondsMechanicSettings: {
      isConvertible: true,
      unitPriceInCents: 300,
      minimumTicketAmountInCents: 900,
      repaymentStartDate: "2023-11-27T20:30:00+00:00",
      repaymentType: "at_maturity",
      repaymentFrequency: "monthly",
      annualInterestNoDecimalRate: 400,
      nonConversionBonusNoDecimalRate: 200,
      penaltyRate: 1,
      durationInMonth: 36,
      conversionHorizonInMonth: null,
      penaltyNoDecimalRate: 1,
      entryFeesNoDecimalRate: 0,
      managementFeesNoDecimalRate: 0,
      annualInterestPercent: 0,
      repaymentScheduleVisibility: "hidden",
      taxesApplied: false,
    },
    projectAgreementAndSecurityContract: null,
    goal: {
      minInCents: 400000,
      maxInCents: 450000,
    },
    startedAt: "2023-11-27T20:10:00+00:00",
    scheduledStartDate: "2023-11-27T20:30:00+00:00",
    scheduledEndDate: null,
    categories: [
      {
        name: "Super cat",
        image: null,
        status: "enable",
        code: "cat_1",
      },
    ],
    tags: [
      {
        name: "Super tag",
        image: null,
        status: "enable",
        code: "tag_1",
      },
    ],
    maturity: {
      name: "Seeding",
      image: null,
      status: "enable",
      code: "seeding",
    },
    taxExemptionMechanics: [],
    impactScoreInPercent: 3,
    currency: "eur",
    holderOrganization: mockOrganizations[0],
    vehicleOrganization: mockOrganizations[0],
    fundraisingProgressMinGoal: 47,
    fundraisingProgressMaxGoal: 47,
    totalAmountCollectedInCents: 187500,
    totalInvestorsCount: 10,
    projectDocuments: [],
    commitmentLetterSigningEnabled: null,
    riskInPercent: null,
    information: {
      foundedYear: 2021,
      location: {
        city: "Bordeaux",
        country: "FR",
      },
      preMoneyValuationInCents: 750000,
      roundTableAmountInCents: 100000,
      revenueLastYearInCents: 150000,
      revenueForecastForCurrentYearInCents: 190000,
      ebitdaRunRateInCents: 8500,
      ebitdaForecastForCurrentYearInCents: 9800,
    },
  },
];
