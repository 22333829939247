import React from "react";
import { PKResult } from "@placekit/client-js";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

interface SearchResultsListProps {
  results: PKResult[];
  onSelect: (result: PKResult) => void;
  open: boolean;
  selectedIndex: number;
}

export function SearchResultsList({
  results,
  onSelect,
  open,
  selectedIndex,
}: SearchResultsListProps) {
  if (!open) return null;

  return (
    <div className="absolute z-50 mt-1 w-full rounded-md border bg-white py-1 shadow-md">
      {results.length === 0 ? (
        <div className="px-4 py-2 text-sm text-gray-500">
          Aucun résultat trouvé
        </div>
      ) : (
        <div className="max-h-60 overflow-auto">
          {results.map((result, index) => (
            <div
              key={`${result.name}-${index}`}
              className={cn(
                "hover:bg-primary-50 cursor-pointer px-4 py-2 text-sm",
                selectedIndex === index && "bg-primary-50",
              )}
              onClick={() => onSelect(result)}
            >
              {result.name}, {result.countrycode?.toUpperCase()}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
