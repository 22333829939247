import { useCallback, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CheckIcon,
  LinkIcon,
  LoaderCircleIcon,
  MailIcon,
  ShareIcon,
  //   XIcon,
} from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useClipboardCopy } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  AlertRebrand,
  AlertRebrandDescription,
  AlertRebrandTitle,
  ButtonRebrand,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSeparator,
  DialogTitle,
  DialogTrigger,
  RadioGroupInputConnected,
  TextInput,
  TextInputConnected,
} from "@tudigo-monorepo/web-tudigo-components";

import type { PropsWithOnUpdateFn } from "./utils/type-defs";

type Mode = "copy" | "send";

export function ComparatorShareDialog({
  className,
  onUpdate,
}: PropsWithOnUpdateFn<boolean | Mode, { className?: string }>) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<Mode>("copy");

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setMode("copy");
      }
      setOpen(open);

      if (onUpdate) {
        onUpdate(open);
      }
    },
    [setMode, setOpen, onUpdate],
  );

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild className="lg:mr-12">
        <ButtonRebrand variant="secondary" className="py-0">
          Partager
          <ShareIcon className="bg-black-400 ml-2 h-5 w-5" />
        </ButtonRebrand>
      </DialogTrigger>

      <DialogContent className={cn("lg:w-[423px]", className)}>
        <DialogHeader>
          <DialogTitle className="text-pretty pr-16 text-left">
            Partager
          </DialogTitle>
          <div className="flex w-full items-center justify-center space-x-4">
            <ButtonRebrand
              data-state-active={mode === "copy"}
              variant="ghost"
              onClick={() => setMode("copy")}
              className="body300-regular data-[state-active=true]:body300-bold hover:text-black-900 rounded-none border-x-0 border-b-2 border-t-0 border-white p-0 transition-colors duration-300 hover:border-neutral-900/20 hover:bg-transparent data-[state-active=true]:border-neutral-900 "
            >
              Copier le lien
            </ButtonRebrand>
            {/* <ButtonRebrand
              data-state-active={mode === "send"}
              variant="ghost"
              onClick={() => setMode("send")}
              className="body300-regular data-[state-active=true]:body300-bold hover:text-black-900 rounded-none border-x-0 border-b-2 border-t-0 border-white p-0 transition-colors duration-300 hover:border-neutral-900/20 hover:bg-transparent data-[state-active=true]:border-neutral-900 "
            >
              Partager par email
            </ButtonRebrand> */}
          </div>
        </DialogHeader>

        <DialogSeparator className="mb-6" />

        {mode === "copy" && <ShareDialogCopyLink onUpdate={onUpdate} />}
        {mode === "send" && (
          <ShareDialogSendEmail onSended={() => handleOpenChange(false)} />
        )}
      </DialogContent>
    </Dialog>
  );
}

function ShareDialogCopyLink({ onUpdate }: PropsWithOnUpdateFn<Mode>) {
  const { copied, copy } = useClipboardCopy();
  const copyValue = window.location.href;

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <p className="body400-medium text-black">
          Copier le lien ci-dessous pour retrouver ces résultas
        </p>
        <TextInput defaultValue={copyValue} />
      </div>

      <DialogSeparator className="mt-6" />

      <DialogFooter>
        <ButtonRebrand
          onClick={() => {
            copy(copyValue);
            if (onUpdate) {
              onUpdate("copy");
            }
          }}
          className="min-w-[155px]"
          disabled={copied}
        >
          {!copied ? (
            <>
              <LinkIcon className="mr-2 h-5 w-5 bg-transparent text-white" />
              <span className="min-w-[93px] text-center">Copier le lien</span>
            </>
          ) : (
            <>
              <CheckIcon className="mr-2 h-5 w-5 bg-transparent text-white" />
              <span className="min-w-[93px] text-center">Lien copié</span>
            </>
          )}
        </ButtonRebrand>
      </DialogFooter>
    </>
  );
}

const formSchema = z.object({
  email: z.string().email({ message: "Adresse email invalide" }),
  sendTo: z.enum(["me", "friend"]).default("me"),
});

function ShareDialogSendEmail({ onSended }: { onSended: () => void }) {
  const { user } = useUserBusiness();

  // Test loading state
  const [isPending, setIsPending] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user?.email ?? "",
      sendTo: "me",
    },
  });

  const handleSendEmailSubmit = async (data: z.infer<typeof formSchema>) => {
    // Simulate sending email
    setIsPending(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsPending(false);

    // If success
    toast(() => (
      <AlertRebrand variant="success">
        <AlertRebrandTitle>
          <CheckIcon className="h-5 w-5" />
          Email envoyé
        </AlertRebrandTitle>
        <AlertRebrandDescription>
          {`Les résultats ont bien été envoyés à ${data.email}`}
        </AlertRebrandDescription>
      </AlertRebrand>
    ));

    // If error
    // toast(() => (
    //   <AlertRebrand variant="error">
    //     <AlertRebrandTitle>
    //       <XIcon className="h-5 w-5" />
    //       Une erreur est survenue
    //     </AlertRebrandTitle>
    //     <AlertRebrandDescription>
    //       {`Les résultats n'ont pas pu être envoyés`}
    //     </AlertRebrandDescription>
    //   </AlertRebrand>
    // ));

    // Close dialog
    onSended();
  };

  const handleSendToValueChange = useCallback(
    (value: string) => {
      if (value === "me" && form.watch("email") !== user?.email) {
        form.setValue("email", user?.email ?? "");
      }
      if (value === "friend" && form.watch("email") === user?.email) {
        form.setValue("email", "");
      }
      form.clearErrors();
    },
    [form, user],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSendEmailSubmit)}>
        <div className="flex flex-col gap-y-4">
          <p className="body400-medium text-black">
            Partager ces résultats par email
          </p>
          <RadioGroupInputConnected
            name="sendTo"
            className="flex flex-col gap-2.5"
            onValueChange={handleSendToValueChange}
            options={[
              { label: "Me l’envoyer par mail", value: "me" },
              { label: "Envoyer à un ami", value: "friend" },
            ]}
          />
          {form.watch("sendTo") === "friend" && (
            <TextInputConnected
              name="email"
              placeholder="Adresse email"
              required
            />
          )}
        </div>

        <DialogSeparator className="mt-6" />

        <DialogFooter>
          <ButtonRebrand
            type="submit"
            className="min-w-[155px]"
            disabled={isPending}
          >
            {isPending ? (
              <>
                <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin bg-transparent text-white" />
                <span className="min-w-[93px] text-center">Envoi en cours</span>
              </>
            ) : (
              <>
                <MailIcon className="mr-2 h-5 w-5 bg-transparent text-white" />
                <span className="min-w-[93px] text-center">Envoyer</span>
              </>
            )}
          </ButtonRebrand>
        </DialogFooter>
      </form>
    </FormProvider>
  );
}
