import React, { KeyboardEvent, useEffect, useState } from "react";
import { PKResult } from "@placekit/client-js";

import { ProjectLocation } from "@tudigo-monorepo/core-tudigo-api-models";
import { TextField } from "@tudigo-monorepo/web-tudigo-components";

import { SearchResultsList } from "./search-results-list";
import { usePlaceSearch } from "./use-place-search";

interface CityCountrySelectorProps {
  onCityCountrySelected: (location: ProjectLocation) => void;
  label?: string;
  required?: boolean;
  defaultValue?: ProjectLocation | null;
  className?: string;
}

export function CityCountrySelector({
  onCityCountrySelected,
  label,
  required = false,
  defaultValue,
  className,
}: CityCountrySelectorProps) {
  const initialSearchValue =
    defaultValue?.city && defaultValue?.country
      ? `${defaultValue.city}, ${defaultValue.country}`
      : "";

  const [searchValue, setSearchValue] = useState(initialSearchValue);

  useEffect(() => {
    if (defaultValue?.city && defaultValue?.country) {
      setSearchValue(`${defaultValue.city}, ${defaultValue.country}`);
      setIsSelected(true);
    }
  }, [defaultValue]);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { results, isSelected, setIsSelected, searchPlaces, formatResult } =
    usePlaceSearch();

  useEffect(() => {
    if (searchValue.length > 0 && !isSelected) {
      searchPlaces(searchValue);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchValue, searchPlaces, isSelected]);

  const handleSelect = (result: PKResult) => {
    setIsSelected(true);
    const formattedResult = formatResult(result);
    onCityCountrySelected(formattedResult);
    setSearchValue(`${formattedResult.city}, ${formattedResult.country}`);
    setOpen(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!open || results.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < results.length - 1 ? prevIndex + 1 : 0,
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : results.length - 1,
        );
        break;
      case "Enter":
        e.preventDefault();
        if (results.length > 0) {
          handleSelect(results[selectedIndex]);
        }
        break;
      case "Escape":
        e.preventDefault();
        setOpen(false);
        break;
    }
  };

  return (
    <div className="relative">
      <TextField
        label={label}
        required={required}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setIsSelected(false);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Rechercher une ville"
        className={className}
      />
      <SearchResultsList
        results={results}
        onSelect={handleSelect}
        open={open}
        selectedIndex={selectedIndex}
      />
    </div>
  );
}
