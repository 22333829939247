import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { TaxExemption, User } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  CollectionRequestParams,
  getApiClient,
  getQueryListKey,
} from "../../..";

export type UserTaxExemptionsFilters = {
  organizationId?: string;
  taxName?: string;
};

type GetUserTaxExemptionsParams = CollectionRequestParams<
  { userId: User["id"] | "me" },
  UserTaxExemptionsFilters
>;

export const getUserTaxExemptions = async (
  params: GetUserTaxExemptionsParams,
) => {
  const apiClient = getApiClient();
  const { fields, filters, pathParams } = params;
  const response = await apiClient.apiV1.userTaxExemptions.getUserTaxExemptions(
    pathParams?.userId || "me",
    {
      ...filters,
      fields,
    },
  );

  return response.data;
};

const userTaxExemptionsQueryKey = getQueryListKey({
  key: QUERY_KEYS.TAX_EXEMPTIONS,
});

export function useInvalidateUserTaxExemptions() {
  const queryClient = useQueryClient();

  return async () => {
    return queryClient.invalidateQueries({
      queryKey: userTaxExemptionsQueryKey,
    });
  };
}

export const useGetUserTaxExemptionsQuery = (
  params: GetUserTaxExemptionsParams,
  options?: UseQueryOptions<TudigoResponse<TaxExemption[]>, TudigoError>,
) => {
  return useQuery({
    queryKey: userTaxExemptionsQueryKey,
    queryFn: () => getUserTaxExemptions(params),
    ...options,
  });
};
