import type { UpdateTaxExemptionBody } from "@tudigo-monorepo/core-tudigo-api";
import type { TaxExemption } from "@tudigo-monorepo/core-tudigo-api-models";

export function isValidCSGTaxExemptionData(data?: UpdateTaxExemptionBody) {
  const properties: (keyof UpdateTaxExemptionBody)[] = [
    "since",
    "state",
    "socialProtectionFound",
    "situation",
  ];

  return properties.every((prop) => {
    const value = data?.[prop];

    return !!value && typeof value === "string" && value.length > 0;
  });
}

export function getDefaultCSGTaxExemptionFormData(taxExemption?: TaxExemption) {
  if (taxExemption && isValidCSGTaxExemptionData(taxExemption)) {
    return {
      ...taxExemption,
      since: new Date(taxExemption.since!),
      city: null,
    };
  }

  return {
    since: null,
    state: "FR",
    situation: null,
    socialProtectionFound: "",
    city: null,
  };
}
