import { JSX, ReactNode } from "react";

import { Notification } from "@tudigo-monorepo/core-tudigo-api-models";
import { getCurrentYear } from "@tudigo-monorepo/core-tudigo-utils";

import {
  isAssemblyNotification,
  isKycNotification,
  isNewsNotification,
  isRepaymentNotification,
  isReportingNotification,
  isTaxResidenceNotification,
  isWMANotification,
} from "../hooks/get-notification-type";

const defaultValues = {
  organizationName: "Organisation inconnue",
  reportingName: "Période inconnue",
  projectName: "Projet inconnu",
  accountName: "Compte inconnu",
  firstName: "Prénom inconnu",
  lastName: "Nom inconnu",
};

export function getNotificationMessage(notification: Notification): ReactNode {
  if (isReportingNotification(notification)) {
    const { reporting } = notification;
    const organizationName =
      reporting?.reportingCampaign?.organization?.name ??
      defaultValues.organizationName;
    const reportingName = reporting?.name ?? defaultValues.reportingName;

    switch (notification.type) {
      case "reporting_to_validate":
        return (
          <>
            {`Rapport à analyser et valider pour `}
            <strong>{organizationName}</strong>
            {` (${reportingName})`}
          </>
        );
      case "reporting_validated":
        return (
          <>
            {`Nouveau rapport disponible pour `}
            <strong>{organizationName}</strong>
            {` (${reportingName})`}
          </>
        );
      case "reporting_alert":
        return (
          <>
            {`30 jours sans réponse pour `}
            <strong>{organizationName}</strong>
            {` (${reportingName})`}
          </>
        );
    }
  }

  if (isKycNotification(notification)) {
    const accountName =
      notification.organization?.name ?? defaultValues.accountName;

    switch (notification.type) {
      case "kyc_validated":
        return (
          <>
            {`Votre identité a été validée pour le compte `}
            <strong>{accountName}</strong>
          </>
        );
      case "kyc_validated_investment_in_progress":
        return (
          <>
            {`Votre identité a été validée pour le compte `}
            <strong>{accountName}</strong>. Finalisez votre investissement !
          </>
        );
      case "kyc_error":
        return (
          <>
            {`Validation de votre compte `}
            <strong>{accountName}</strong>
            {` - erreurs à corriger`}
          </>
        );
    }
  }

  if (isRepaymentNotification(notification)) {
    const projectName =
      notification.creditorRepayment?.project?.content?.name ??
      defaultValues.projectName;

    return (
      <>
        {`Un versement a été effectué pour `}
        <strong>{projectName}</strong>
      </>
    );
  }

  if (isNewsNotification(notification)) {
    const projectName =
      notification.news?.project?.content?.name ?? defaultValues.projectName;

    return (
      <>
        {`Nouvelle actualité sur votre investissement avec `}
        <strong>{projectName}</strong>
      </>
    );
  }

  if (isWMANotification(notification)) {
    const firstName =
      notification.wmaCustomer?.customerUser?.firstName ??
      defaultValues.firstName;
    const lastName =
      notification.wmaCustomer?.customerUser?.lastName ??
      defaultValues.lastName;

    switch (notification.type) {
      case "wma_invitation_refused":
        return (
          <>
            {`Refus d'ajout de `}
            <strong>{firstName}</strong>
            {` (${lastName}) à votre compte Tudigo`}
          </>
        );
      case "wma_invitation_accepted":
        return (
          <>
            {`Acceptation d'ajout de `}
            <strong>{firstName}</strong>
            {` (${lastName}) à votre compte Tudigo`}
          </>
        );
    }
  }

  if (isAssemblyNotification(notification)) {
    const projectName = notification.metadata?.projectName;
    const holdingName = notification.metadata?.holdingName;

    switch (notification.type) {
      case "assembly_convocation":
        return (
          <>
            {`Répondez à la convocation pour l'assemblée `}
            <strong>
              {holdingName} - {projectName}
            </strong>
          </>
        );
      case "assembly_vote_reminder":
        return (
          <>
            {`Nous restons en attente de votre vote pour l'assemblée `}
            <strong>
              {holdingName} - {projectName}
            </strong>
          </>
        );
      case "assembly_vote_request":
        return (
          <>
            {`Les votes pour l'assemblée `}
            <strong>
              {holdingName} - {projectName}
            </strong>
            {` sont ouverts`}
          </>
        );
    }
  }

  if (isTaxResidenceNotification(notification)) {
    return (
      <>
        Action requise : résidence fiscale <strong>{getCurrentYear()}</strong> à
        mettre à jour !
      </>
    );
  }

  return <>Notification inconnue</>;
}

export type NotificationLinkConfig = {
  url: string;
  openInNewTab: boolean;
};

export function getNotificationLinkConfig(
  notification: Notification,
): NotificationLinkConfig {
  const backOfficeUrl = import.meta.env.VITE_BACKOFFICE_URL;
  const webappUrl = import.meta.env.VITE_APP_URL;
  const currentUrl = window.location.origin;

  let url: string;

  switch (notification.type) {
    case "reporting_to_validate":
      url = `${backOfficeUrl}/reportings/${notification.reporting.id}`;
      break;
    case "reporting_validated":
      url = `${webappUrl}/my-account/my-investments/${notification.metadata?.investmentId}/reportings/${notification.reporting?.id}`;
      break;
    case "reporting_alert":
      url = `${backOfficeUrl}/reportings?page=0&pageSize=20`;
      break;
    case "kyc_validated":
      url = `${webappUrl}/profile-and-accounts/organizations`;
      break;
    case "kyc_validated_investment_in_progress":
      url = `${webappUrl}/projects/${notification.metadata?.projectId}/invest/${notification.metadata?.investmentId}/select-amount`;
      break;
    case "kyc_error":
      url = `${webappUrl}/profile-and-accounts/organizations/${notification.organization.id}`;
      break;
    case "repayment_received":
      url = `${webappUrl}/my-account/my-repayments?investmentId=${notification.creditorRepayment.investment?.id}`;
      break;
    case "news_invest_created":
      url = `${webappUrl}/my-account/my-investments/${notification.metadata?.investmentId}/news`;
      break;
    case "wma_invitation_refused":
      url = `${webappUrl}/wma/customers/list`;
      break;
    case "wma_invitation_accepted":
      url = `${webappUrl}/wma/customers/list`;
      break;
    case "assembly_convocation":
      url = `${webappUrl}/my-account/my-investments/${notification.metadata?.investmentId}/assemblies/${notification.assembly.id}/assembly-participant/${notification.assembly.myParticipation?.id}/detail/vote`;
      break;
    case "assembly_vote_reminder":
      url = `${webappUrl}/my-account/my-investments/${notification.metadata?.investmentId}/assemblies/${notification.assembly.id}/assembly-participant/${notification.assembly.myParticipation?.id}/detail/vote`;
      break;
    case "assembly_vote_request":
      url = `${webappUrl}/my-account/my-investments/${notification.metadata?.investmentId}/assemblies/${notification.assembly.id}/assembly-participant/${notification.assembly.myParticipation?.id}/detail/vote`;
      break;
    case "organization_address_to_update":
      url = `${webappUrl}/profile-and-accounts/tax`;
      break;

    default:
      url = `${webappUrl}/`;
  }

  const openInNewTab = !url.startsWith(currentUrl);

  return {
    url,
    openInNewTab,
  };
}
