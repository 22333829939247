import { useCallback } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { signup } from "../utils/signup";

export function SignupButton({
  className,
  onSignup,
}: {
  className?: string;
  onSignup?: () => void;
}) {
  const { t } = useTranslation();

  const handleSignup = useCallback(() => {
    if (onSignup) onSignup(); // Callback before redirecting
    signup();
  }, [onSignup]);

  return (
    <ButtonRebrand
      size="lg"
      onClick={handleSignup}
      variant="black"
      className={cn("body300-medium !text-base", className)}
    >
      {t("signup")}
    </ButtonRebrand>
  );
}
