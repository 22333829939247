import type { TaxResidenceAddressFormData } from "../components/tax-residence-address/tax-residence-address-form";

export function isValidTaxResidenceAddress(
  address?: TaxResidenceAddressFormData | null,
): boolean {
  const isValid = (value: string | null) => {
    return value !== null && value.length > 0;
  };

  return !!address && isValid(address.country);
}
