import { useMemo } from "react";

import { useUserBusiness } from "./use-user-business";

type Reason = "unauthenticated" | "incomplete-profile" | null;

export function useRestrictedAccess() {
  const { isQualifiedOrWma, isConnected } = useUserBusiness();

  const reason = useMemo<Reason>(() => {
    if (!isConnected) return "unauthenticated";
    if (!isQualifiedOrWma) return "incomplete-profile";
    return null;
  }, [isConnected, isQualifiedOrWma]);

  return {
    restricted: reason !== null,
    reason,
  };
}
