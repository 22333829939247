import type { ReactNode } from "react";
import { UserIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceContext,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { NotificationPopoverNavbar } from "@tudigo-monorepo/web-feature-notifications";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

export const NavbarUserBlock = () => {
  const { currentPagePaths } = useSpaceContext();
  const { user } = useUserBusiness();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-4 lg:flex-row">
      <NotificationPopoverNavbar />
      <Link to={currentPagePaths[PageEnum.DASHBOARD]} className="w-full">
        <ButtonLayout>
          <span className="w-fit text-wrap sm:text-left">
            {`${t("my_account")} `}
            <span className="hidden xl:inline-block">
              ({`${user.firstName} ${user.lastName}`})
            </span>
          </span>
        </ButtonLayout>
      </Link>
    </div>
  );
};

function ButtonLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <ButtonRebrand
        variant="accent-light"
        size="lg"
        className="body300-medium group w-full gap-x-2 !text-base lg:ml-auto lg:hidden lg:w-fit"
      >
        <UserIcon className="text-accent h-5 min-h-4 w-5 min-w-4" />
        {children}
      </ButtonRebrand>

      <ButtonRebrand
        variant="ghost"
        size="lg"
        className="body300-medium group hidden w-full gap-x-2 !text-base lg:ml-auto lg:flex lg:w-fit"
      >
        <UserIcon
          strokeWidth={2.5}
          className="group-hover:text-accent h-5 min-h-4 w-5 min-w-4 text-neutral-900 transition-colors"
        />
        {children}
      </ButtonRebrand>
    </>
  );
}
