import { useCallback } from "react";

import type { User } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  datadogIdentify,
  hubspotIdentify,
  useAnalytics,
} from "@tudigo-monorepo/web-tudigo-tracking";

export function useSetupAnalytics() {
  const analytics = useAnalytics();

  return useCallback(
    (user: User) => {
      analytics.identify(user.id);
      hubspotIdentify(user.email);
      datadogIdentify(user);
    },
    [analytics],
  );
}
