import * as React from "react";
import { fr } from "date-fns/locale";
import { DayPicker } from "react-day-picker";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../../../old/icons/icon";
import { buttonRebrandCVA } from "../../../../rebrand/ui/button-rebrand/button-rebrand";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      locale={fr}
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonRebrandCVA({ variant: "ghost" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        day: cn(
          buttonRebrandCVA({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
        ),
        cell: cn(
          props?.mode === "single" && "rounded-lg",
          `
            relative h-9 w-9 p-0
            text-center text-sm
            focus-within:relative
            focus-within:z-20
          `,
          `
            first:[&:has([aria-selected])]:rounded-l-lg
            last:[&:has([aria-selected])]:rounded-r-lg
          `,
          `
            [&:has([aria-selected])]:bg-accent-light
          `,
          `
            [&:has([aria-selected].day-range-start)]:bg-primary
            [&:has([aria-selected].day-range-start)]:text-primary-foreground
            [&:has([aria-selected].day-range-start)]:rounded-l-lg
          `,
          `
            [&:has([aria-selected].day-range-end)]:bg-primary
            [&:has([aria-selected].day-range-end)]:text-primary-foreground
            [&:has([aria-selected].day-range-end)]:rounded-r-lg
          `,
        ),

        day_selected: cn(
          props?.mode === "single" && "!bg-primary text-primary-foreground ",
          "rounded-lg",
        ),
        day_range_start: "day-range-start text-primary-foreground rounded-lg",
        day_range_end: "day-range-end text-primary-foreground",
        day_range_middle: "day-range-middle",

        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <Icon name="ChevronLeft" className="h-4 w-4" />
        ),
        IconRight: ({ ...props }) => (
          <Icon name="ChevronRight" className="h-4 w-4" />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
