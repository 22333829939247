import { getAuthorizeUrl } from "./get-authorize-url";
import { storeCallbackRedirectUrl } from "./store-callback-redirect-url";

export function signup(): void {
  storeCallbackRedirectUrl();
  const authUrl = getAuthorizeUrl();
  const authUrlObject = new URL(authUrl);
  authUrlObject.searchParams.append("register", "1");
  window.location.replace(authUrlObject.toString());
}
