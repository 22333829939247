import { Comment } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, TudigoResponse } from "../api";

export const commentsEndPoints = (request: Request) => ({
  getCommentsByClubId: (clubId: string, query?: { [key: string]: unknown }) =>
    request<TudigoResponse<Comment[]>, void>({
      path: `/api/v1/clubs/${clubId}/comments`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
    }),

  createComment: (body?: { [key: string]: unknown }) =>
    request<TudigoResponse<Comment>, void>({
      path: "/api/v1/comments",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
    }),
});
