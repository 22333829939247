import { Outlet } from "react-router-dom";

import { FooterRebrand } from "./components/footer-rebrand/footer-rebrand";
import { NavbarRebrand } from "./components/navbar-rebrand/navbar-rebrand";

export function AppLayout() {
  return (
    <div>
      <NavbarRebrand />

      <main>
        <Outlet />
      </main>

      <FooterRebrand />
    </div>
  );
}
