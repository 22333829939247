import { Club, ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";
import { BlogCarousel } from "@tudigo-monorepo/feature-webflow";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Alert,
  DiscordCard,
  HighlightsCard,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubNextSteps, Step } from "../../../../components/club-next-steps";

type FunnelClubSuccessProps = {
  club: Club;
  clubMember: ClubMember;
};

export const FunnelClubSuccess = (props: FunnelClubSuccessProps) => {
  const { club, clubMember } = props;

  const { t } = useTranslation();
  const { user } = useUserBusiness();

  const nextStepsContent = [
    {
      step: 1,
      title: t("my.account.web.app.clubs.nextStep.duration.title"),
      description: t("my.account.web.app.clubs.nextStep.duration.description"),
      state: "done",
    },
    {
      step: 2,
      title: t("my.account.web.app.clubs.nextStep.discord.title"),
      description: t("my.account.web.app.clubs.nextStep.discord.description"),
    },
    {
      step: 3,
      title: t("my.account.web.app.clubs.nextStep.linkedin.title"),
      description: t("my.account.web.app.clubs.nextStep.linkedin.description"),
    },
  ];

  return (
    <>
      <div className="border-b pb-3">
        <Title>{t("my.account.web.app.clubs.success.title")}</Title>
      </div>
      <div className="flex flex-col gap-5">
        <Alert
          variant="success"
          icon="Notification"
          title={t("my.account.web.app.clubs.success.alert.title", {
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
          description={t("my.account.web.app.clubs.success.alert.description", {
            clubName: club?.name,
            clubLeader: club?.clubLeader,
          })}
          size="F"
        />
        <div className="border-success-medium flex items-center justify-end gap-8 rounded-lg border p-6">
          <Typography variant="body1">
            {t("my.account.web.app.clubs.success.amount")}
          </Typography>
          <div className="font-obviously text-success text-lg">
            {formatCurrency(clubMember?.subscription?.price?.unitAmountInCents)}
          </div>
        </div>
        <div className="flex flex-col gap-3 rounded-lg border md:flex-row">
          <div className="p-6 lg:w-1/2">
            <ClubNextSteps steps={nextStepsContent as Step[]} />
          </div>
          <div className="lg:relative lg:w-1/2">
            <img
              src={club?.image?.url ?? ""}
              alt="club cover"
              className="h-full w-full object-cover lg:absolute lg:[clip-path:polygon(07%_0%,100%_0%,100%_100%,0%_100%)]"
            />
          </div>
        </div>
      </div>
      <section className="bg-light-3 flex w-full flex-col items-center gap-y-6 px-6 py-8">
        <div className="flex w-full flex-col gap-6 sm:flex-row">
          <DiscordCard />
          <HighlightsCard />
        </div>
        <BlogCarousel />
      </section>
    </>
  );
};
