import { forwardRef, type ComponentPropsWithoutRef } from "react";

import { ProjectMechanicSettingsCard } from "../../../../pages/shared-internal/components/project-mechanic-settings-card";
import {
  ProjectCard,
  ProjectCardAmount,
  ProjectCardAvatar,
  ProjectCardBadges,
  ProjectCardClubAvatar,
  ProjectCardContent,
  ProjectCardCover,
  ProjectCardDescription,
  ProjectCardFooter,
  ProjectCardHeader,
  ProjectCardImage,
  ProjectCardImageFallback,
  ProjectCardLabel,
  ProjectCardPercentage,
  ProjectCardStatusBadge,
} from "../project-card";

export const ProjectCardComposed = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof ProjectCard>
>(({ project, to, className, ...props }, ref) => {
  return (
    <ProjectCard
      ref={ref}
      project={project}
      to={to}
      className={className}
      {...props}
    >
      <ProjectCardCover>
        <ProjectCardImage />
        <ProjectCardImageFallback />
        <ProjectCardStatusBadge />
        <ProjectCardClubAvatar />
      </ProjectCardCover>
      <ProjectCardContent>
        <ProjectCardHeader>
          <ProjectCardAvatar />
          <ProjectCardLabel />
        </ProjectCardHeader>
        <ProjectCardBadges />
        <ProjectCardDescription />
        <ProjectMechanicSettingsCard project={project} />
        <ProjectCardFooter>
          <ProjectCardAmount />
          <ProjectCardPercentage />
        </ProjectCardFooter>
      </ProjectCardContent>
    </ProjectCard>
  );
});
