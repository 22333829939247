import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type PropsWithChildren,
} from "react";
import { ChevronDownIcon, InfoIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  ButtonRebrand,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from "@tudigo-monorepo/web-tudigo-components";

import { ComparatorGrid, ComparatorGridColumn } from "./comparator-grid";
import { useComparatorScroller } from "./comparator-scroller/store/hooks/use-comparator-scroller";
import { useComparator } from "./comparator.context";

type ComparatorTableContextValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ComparatorTableContext =
  createContext<ComparatorTableContextValue | null>(null);

function useComparatorTable() {
  const context = useContext(ComparatorTableContext);

  if (context === null) {
    throw new Error(
      "useComparatorTable must be used within a ComparatorTableProvider",
    );
  }

  return context;
}

const DEFAULT_ROW_HEIGHT_IN_PX = 42;
const ROW_LABEL_MOBILE_WIDTH_IN_PX = 172;
const ROW_LABEL_BORDER_Y_IN_PX = 2;
const ROW_GAP_X_IN_PX = 16;

type ComparatorTableProps = {
  defaultOpen?: boolean;
};

function ComparatorTableProvider({
  children,
  defaultOpen = false,
}: PropsWithChildren<ComparatorTableProps>) {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const contextValue = useMemo<ComparatorTableContextValue>(
    () => ({ open, setOpen }),
    [open],
  );

  return (
    <ComparatorTableContext.Provider value={contextValue}>
      {children}
    </ComparatorTableContext.Provider>
  );
}

const ComparatorTable = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div"> & ComparatorTableProps
>(({ children, className, defaultOpen = false, ...props }, ref) => {
  const { selectionCount } = useComparator();

  return (
    <ComparatorTableProvider defaultOpen={defaultOpen}>
      <div
        ref={ref}
        data-comparator-table=""
        className={cn(
          "w-full min-w-max lg:min-w-full",
          selectionCount > 2 && "lg:min-w-max",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </ComparatorTableProvider>
  );
});

const ComparatorTableTrigger = forwardRef<
  ElementRef<typeof ButtonRebrand>,
  ComponentPropsWithoutRef<typeof ButtonRebrand>
>(({ children, className, ...props }, ref) => {
  const { open, setOpen } = useComparatorTable();
  const {
    state: { maxScrollWidth },
  } = useComparatorScroller();

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  const minWidth = (maxScrollWidth ?? 0) + ROW_LABEL_MOBILE_WIDTH_IN_PX;

  return (
    <ButtonRebrand
      ref={ref}
      style={{ minWidth }}
      data-comparator-table-trigger=""
      onClick={toggleOpen}
      variant="ghost"
      className={cn(
        "relative z-20 min-h-10 w-full justify-start p-0 lg:min-w-full",
        "border-x-0 border-y border-neutral-300",
        "bg-accent-super-light-rebrand rounded-none",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "h-10 min-w-[200px]",
          "sticky left-4 top-0 -translate-x-4 lg:fixed lg:left-[86px] lg:top-auto",
          "flex items-center justify-start px-4",
          "text-accent font-roobert rounded-none bg-inherit text-xs font-semibold",
          "border-y border-neutral-300",
        )}
      >
        {children}
        <ChevronDownIcon
          className={cn(
            "ml-2 h-4 w-4 shrink-0 transition-transform duration-300",
            open && "rotate-180",
          )}
        />
      </div>
    </ButtonRebrand>
  );
});

const ComparatorTableContent = forwardRef<
  ElementRef<"ul">,
  ComponentPropsWithoutRef<"ul">
>(({ children, className, ...props }, ref) => {
  const contentRef = useRef<HTMLUListElement>(null);
  const { open } = useComparatorTable();

  useImperativeHandle(ref, () => contentRef.current!);

  return (
    <ul
      ref={contentRef}
      data-comparator-table-content=""
      className={cn(
        "h-0 w-full overflow-hidden opacity-0 lg:-mt-1.5",
        "ease transition-opacity duration-300",
        open &&
          "h-auto divide-y divide-solid divide-neutral-300 overflow-visible opacity-100",
        className,
      )}
      {...props}
    >
      {children}
    </ul>
  );
});

const ComparatorTableRow = forwardRef<
  ElementRef<"li">,
  ComponentPropsWithoutRef<"li">
>(({ children, className, ...props }, ref) => {
  const rowId = useId();
  const { selectionCount, config } = useComparator();
  const {
    state: { maxScrollWidth, scrollableItemWidth },
  } = useComparatorScroller();
  const { open } = useComparatorTable();

  const smallDeviceGridTemplateColumns = `${
    ROW_LABEL_MOBILE_WIDTH_IN_PX - ROW_GAP_X_IN_PX
  }px minmax(auto, ${scrollableItemWidth}px)`;

  return (
    <li
      ref={ref}
      id={rowId}
      data-comparator-table-row=""
      className={cn(
        open ? "h-auto min-h-10" : "h-0 min-h-0",
        "relative",
        "odd:bg-black-400 even:bg-white",
        "[&*border-neutral-300]",
        // Labels
        "[&>div>[data-comparator-table-row-label]]:odd:bg-black-400",
        "[&>div>[data-comparator-table-row-label]]:even:bg-white",
        "[&>div>[data-comparator-table-row-label]]:lg:even:-mt-px",
        "[&>div>[data-comparator-table-row-label]]:lg:even:border-y",
        // Values
        "[&>div>*>[data-comparator-table-row-value]]:odd:bg-black-400",
        "[&>div>*>[data-comparator-table-row-value]]:even:bg-white",
        className,
      )}
      {...props}
    >
      <ComparatorGrid
        className={cn(
          "lg:!grid-cols-[initial]",
          open ? "h-auto min-h-10" : "h-0 min-h-0",
        )}
        style={{
          minWidth:
            selectionCount === config.minSelection ? maxScrollWidth : undefined,
          gridTemplateColumns: smallDeviceGridTemplateColumns,
        }}
      >
        {children}
      </ComparatorGrid>
    </li>
  );
});

const ComparatorTableRowLabel = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span">
>(({ children, className, ...props }, ref) => {
  const { isLoading } = useComparator();
  const { open } = useComparatorTable();

  const labelRef = useRef<HTMLSpanElement>(null);
  useImperativeHandle(ref, () => labelRef.current!);

  const [rowHeight, setRowHeight] = useState<number>(DEFAULT_ROW_HEIGHT_IN_PX);

  useEffect(() => {
    if (!open || isLoading) return;

    const rowElement = labelRef.current?.closest("[data-comparator-table-row]");

    if (rowElement) {
      setRowHeight(rowElement.clientHeight + ROW_LABEL_BORDER_Y_IN_PX);
    }
  }, [children, isLoading, open]);

  return (
    <span
      ref={labelRef}
      data-comparator-table-row-label=""
      style={{
        height: `${rowHeight}px`,
        width: ROW_LABEL_MOBILE_WIDTH_IN_PX,
      }}
      className={cn(
        "sticky lg:fixed",
        "left-4 lg:left-auto",
        "-translate-x-4 lg:-translate-x-[200px]",
        "h-auto lg:!w-[200px]",
        "inline-flex items-center gap-x-2",
        "px-4 py-3 lg:px-4",
        "body200-regular text-neutral-900",
        !open && "hidden",
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
});

const ComparatorTableRowValue = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span"> & { loaderClassName?: string }
>(({ children, className, loaderClassName }, ref) => {
  const { isLoading } = useComparator();
  const {
    state: { scrollableItemWidth },
  } = useComparatorScroller();
  const { open } = useComparatorTable();

  return (
    <ComparatorGridColumn className={cn(open ? "h-full" : "h-0")}>
      <span
        ref={ref}
        data-comparator-table-row-value=""
        style={{
          maxWidth: scrollableItemWidth - ROW_LABEL_MOBILE_WIDTH_IN_PX,
        }}
        className={cn(
          open ? "h-full py-3" : "h-0 py-0",
          "w-full lg:!max-w-full",
          "inline-flex items-center gap-x-2",
          "px-2",
          "body200-medium text-xs text-neutral-900",
          className,
        )}
      >
        {isLoading ? (
          <Skeleton
            className={cn("my-auto block min-h-5 w-full", loaderClassName)}
          />
        ) : (
          children
        )}
      </span>
    </ComparatorGridColumn>
  );
});

const ComparatorTableTooltip = forwardRef<
  ElementRef<typeof PopoverContent>,
  ComponentPropsWithoutRef<typeof PopoverContent>
>(({ children, className, ...props }, ref) => (
  <Popover>
    <PopoverTrigger>
      <InfoIcon className="h-4 w-4 text-neutral-900" />
    </PopoverTrigger>
    <PopoverContent
      ref={ref}
      side="top"
      className={cn(
        "font-montserrat block min-w-[200px] text-balance p-3 text-center text-xs",
        className,
      )}
      {...props}
    >
      {children}
    </PopoverContent>
  </Popover>
));

export {
  ComparatorTable,
  ComparatorTableTrigger,
  ComparatorTableContent,
  ComparatorTableRow,
  ComparatorTableRowLabel,
  ComparatorTableRowValue,
  ComparatorTableTooltip,
};
