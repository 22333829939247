import { useMemo, type PropsWithChildren } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderCircleIcon } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import type { Country as CountryCode } from "react-phone-number-input";
import { z } from "zod";

import type { Address } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  AddressSelector,
  AlertRebrand,
  AlertRebrandDescription,
  ButtonRebrand,
  CountrySelectInputConnected,
  TextInputConnected,
} from "@tudigo-monorepo/web-tudigo-components";

import { isValidTaxResidenceAddress } from "../../utils/is-valid-tax-residence-address";

const formSchema = z.object({
  country: z.string().min(2).max(2).nullable(),
  street1: z
    .string()
    .min(1, { message: "Veuillez renseigner une adresse" })
    .nullable(),
  postalCode: z
    .string()
    .min(1, { message: "Veuillez renseigner un code postal" })
    .nullable(),
  city: z
    .string()
    .min(1, { message: "Veuillez renseigner une ville" })
    .nullable(),
});

type TaxResidenceAddressFormData = z.infer<typeof formSchema>;

type TaxResidenceAddressFormProps = {
  onSubmit: (data: TaxResidenceAddressFormData) => void;
  className?: string;
  defaultValues?: TaxResidenceAddressFormData;
  isLoading?: boolean;
};

function TaxResidenceAddressForm({
  onSubmit,
  className,
  defaultValues,
  isLoading = false,
}: PropsWithChildren<TaxResidenceAddressFormProps>) {
  const form = useForm<TaxResidenceAddressFormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const formValues = form.watch();

  const isValidForm = useMemo(
    () => form.formState.isValid || isValidTaxResidenceAddress(formValues),
    [form.formState.isValid, formValues, isValidTaxResidenceAddress],
  );

  const handleAddressChange = (address: Address) => {
    if (address.street1) {
      form.setValue("street1", address.street1);
    }
    if (address.postalCode) {
      form.setValue("postalCode", address.postalCode);
    }
    if (address.city) {
      form.setValue("city", address.city);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-4"
      >
        <div className="flex flex-col gap-y-4 rounded-lg border border-neutral-200 p-4">
          <div className={cn("flex flex-col gap-y-6", className)}>
            <CountrySelectInputConnected
              name="country"
              onCountryChange={() => form.reset()}
              label="Pays"
              required
            />
            <AddressSelector
              label="Rechercher votre adresse"
              className="space-y-2"
              country={form.watch("country") as CountryCode}
              onAddressSelected={handleAddressChange}
            />
            <TextInputConnected
              name="street1"
              label="N° et rue"
              placeholder="Lorem ipsum"
              required
            />
            <TextInputConnected
              name="postalCode"
              label="Code postal"
              placeholder="Lorem ipsum"
              required
            />
            <TextInputConnected
              name="city"
              label="Ville"
              placeholder="Lorem ipsum"
              required
            />
          </div>
          <AlertRebrand variant="warning" className="mt-4">
            <AlertRebrandDescription>
              Cette information ne sera plus modifiable une fois validée
            </AlertRebrandDescription>
          </AlertRebrand>
        </div>

        {isValidForm && (
          <ButtonRebrand
            type="submit"
            variant="accent"
            className="ml-auto"
            disabled={isLoading}
          >
            Confirmer
            {isLoading && (
              <LoaderCircleIcon className="ml-2 h-5 w-5 animate-spin text-white" />
            )}
          </ButtonRebrand>
        )}
      </form>
    </FormProvider>
  );
}

export { type TaxResidenceAddressFormData, TaxResidenceAddressForm };
