import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { InputConnectedProps } from "../../components/input.type";
import { CountrySelectInput, CountrySelectProps } from "./country-select-input";

export const CountrySelectInputConnected = ({
  name,
  label,
  description,
  required,
  disabled,
  className,
  onCountryChange,
}: InputConnectedProps & {
  onCountryChange?: CountrySelectProps["onChange"];
}) => {
  const form = useFormContext();

  if (!form) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const handleCountryChange = (
    field: ControllerRenderProps<FieldValues, string>,
  ) => {
    field.onChange(field.value);
    onCountryChange?.(field.value);
  };

  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <FormItem>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <CountrySelectInput
            value={field.value}
            onChange={() => handleCountryChange(field)}
            disabled={disabled}
            className={cn("w-full justify-normal", className)}
          />
          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
};
