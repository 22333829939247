import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  useImpersonateId,
  useSetImpersonateId,
} from "@tudigo-monorepo/web-tudigo-auth-store";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

export function LeaveImpersonateButton(props: { user: User | null }) {
  const impersonateId = useImpersonateId();
  const setImpersonateId = useSetImpersonateId();

  const onLeaveImpersonateClick = () => {
    setImpersonateId(null);
    window.close();
  };

  if (!impersonateId) {
    return null;
  }

  return (
    <div className="fixed bottom-20 right-3 z-50">
      <Button
        onClick={onLeaveImpersonateClick}
        className="min-w-52 max-w-56 text-pretty"
        variant="tertiary"
        label={
          <span>
            Quitter le profil
            {props.user && (
              <>
                {" "}
                de <br />
                <strong>
                  {props.user.firstName} {props.user.lastName}
                </strong>
              </>
            )}
          </span>
        }
      />
    </div>
  );
}
