import { useEffect } from "react";

import { useGetConfigurationQuery } from "@tudigo-monorepo/web-tudigo-api-client";

import { useSessionStore } from "../shared-external/providers/session/session-store";

export function useSetupSessionConfiguration() {
  const setConfiguration = useSessionStore((state) => state.setConfiguration);
  const setError = useSessionStore((state) => state.setError);

  const {
    data: configResponse,
    error: configError,
    ...configQuery
  } = useGetConfigurationQuery();

  useEffect(() => {
    if (configError) {
      setError(configError);
      return;
    }

    if (configResponse) {
      setConfiguration(configResponse.data);
    }
  }, [configError, setError, configResponse, setConfiguration]);

  return { configResponse, configError, ...configQuery };
}
