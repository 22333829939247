import { createContext, useContext } from "react";

import {
  getPagePathsBySpace,
  pathsByPagesAndSpaces,
} from "../../utils/space-navigation";
import { PageEnum, SpaceEnum, type SpaceType } from "../../utils/space-types";

type SpaceContextType = {
  currentSpace: SpaceType;
  currentPagePaths: Record<PageEnum, string>;
  pagePaths: Record<PageEnum, Record<SpaceEnum, string>>;
  canSwitchSpace: boolean;
};

export const SpaceContext = createContext<SpaceContextType>({
  currentSpace: `${SpaceEnum.INVESTOR}`,
  currentPagePaths: getPagePathsBySpace(`${SpaceEnum.INVESTOR}`),
  pagePaths: pathsByPagesAndSpaces,
  canSwitchSpace: false,
});

export function useSpaceContext() {
  return useContext(SpaceContext);
}
