import type { PropsWithChildren } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../../old/icons/icon";

const STORAGE_KEY = "sidebar-store";
const INITIAL_STATE = "expanded";

type SidebarState = "expanded" | "collapsed";

type SidebarStore = {
  state: SidebarState;
  setState: (state: SidebarState) => void;
};

const useSidebarStore = create<SidebarStore>()(
  persist(
    (set) => ({
      state: INITIAL_STATE,
      setState: (state) => set({ state }),
    }),
    {
      name: STORAGE_KEY,
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

type SidebarProps = {
  className?: string;
  defaultState?: SidebarState;
};

function Sidebar({ children, className }: PropsWithChildren<SidebarProps>) {
  const { state, setState } = useSidebarStore();

  return (
    <div
      data-state={state}
      className={cn(
        "hidden lg:flex lg:flex-col",
        "sticky bottom-0 left-0 top-0 py-8",
        "text-primary border-r border-neutral-500 bg-white",
        "overflow-y-auto overflow-x-hidden",
        "duration-200 ease-in-out",
        "data-[state=expanded]:lg:w-[264px] data-[state=expanded]:lg:min-w-[264px]",
        "data-[state=collapsed]:w-[60px] data-[state=collapsed]:min-w-[60px] data-[state=collapsed]:items-center",
        className,
      )}
    >
      <div
        data-state={state}
        className="w-full data-[state=collapsed]:px-2 data-[state=expanded]:px-6"
      >
        {children}
      </div>
      <button
        data-state={state}
        onClick={() => {
          setState(state === "expanded" ? "collapsed" : "expanded");
        }}
        className={cn(
          "absolute right-3 top-[70px] grid h-10 rotate-0 place-items-center",
          "data-[state=collapsed]:w-full data-[state=expanded]:w-11",
          "data-[state=collapsed]:right-0",
          "data-[state=collapsed]:mx-auto",
          "data-[state=collapsed]:-rotate-180",
        )}
      >
        <span className="sr-only">
          {state === "expanded" ? "Réduire" : "Agrandir"}
        </span>
        <Icon name="NavCloser" primaryColor={themeColors["dark-2"]} />
      </button>
    </div>
  );
}

export { Sidebar, useSidebarStore };
