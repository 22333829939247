import { forwardRef, Ref, useState, type PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { NavbarLabel } from "../navbar-label";

type MenuValue = "invest" | "funds" | "testimonials" | "about" | "resources";

export interface VerticalMenuCollapseProps extends PropsWithChildren {
  menuValue: MenuValue;
}

export const VerticalMenuCollapse = forwardRef(
  (props: VerticalMenuCollapseProps, ref: Ref<HTMLDivElement>) => {
    const { children } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = () => setIsOpen(!isOpen);

    return (
      <div
        ref={ref}
        className={cn("max-h-[62px] w-full overflow-hidden transition-all", {
          "max-h-max": isOpen,
        })}
      >
        <button
          onClick={handleOpenChange}
          className={cn(
            "border-black-500 mb-5 flex w-full list-none items-center justify-between border-b py-4",
          )}
        >
          <NavbarLabel className="border-none">
            {t(`navbar.link.${props.menuValue}.label`)}
          </NavbarLabel>
          <Icon
            name="ChevronUp"
            className={cn("rotate-180 transition-transform", {
              "rotate-0": isOpen,
            })}
          />
        </button>
        <section
          className={cn("-translate-y-6 opacity-0 transition duration-300", {
            "translate-y-0 opacity-100": isOpen,
          })}
        >
          {children}
        </section>
      </div>
    );
  },
);
