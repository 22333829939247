import type { OrganizationAddressHistory } from "@tudigo-monorepo/core-tudigo-api-models";

import {
  ContentType,
  type Request,
  type RequestParams,
  type TudigoResponse,
} from "../api";

type UpdateOrganizationAddressHistoryRequestBody = Required<
  Pick<OrganizationAddressHistory, "address" | "confirm">
>;

export type UpdateOrganizationAddressHistoryParams = {
  addressId: string;
  data: UpdateOrganizationAddressHistoryRequestBody;
};

export const organizationAddressHistoryEndpoints = (request: Request) => ({
  updateOrganizationAddressHistory: (
    addressId: string,
    body: UpdateOrganizationAddressHistoryRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationAddressHistory>, void>({
      path: `/api/v1/organization-address-histories/${addressId}`,
      method: "PUT",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
