import { createContext, useContext, type PropsWithChildren } from "react";

import type { Project } from "@tudigo-monorepo/core-tudigo-api-models";

type ProjectCardContextType = {
  project: Project;
};

const ProjectCardContext = createContext<ProjectCardContextType | null>(null);

const useProjectCard = () => {
  const context = useContext(ProjectCardContext);

  if (!context) {
    throw new Error("useProjectCard must be used within a ProjectCardProvider");
  }

  return context;
};

const ProjectCardProvider = ({
  project,
  children,
}: PropsWithChildren<{ project: Project }>) => {
  return (
    <ProjectCardContext.Provider value={{ project }}>
      {children}
    </ProjectCardContext.Provider>
  );
};

export { ProjectCardProvider, useProjectCard };
