import React, { ReactNode } from "react";
import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

type AnalyticsContextType = {
  analytics: AnalyticsBrowser;
};

export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  analytics: {} as AnalyticsBrowser,
});

type AnalyticsProviderProps = {
  writeKey: string;
  siteId: string;
  children: ReactNode;
};

export function AnalyticsProvider(props: AnalyticsProviderProps) {
  const analytics: AnalyticsBrowser = AnalyticsBrowser.load(
    { writeKey: props.writeKey, cdnURL: "https://cdp-eu.customer.io" },
    {
      integrations: {
        "Customer.io In-App Plugin": {
          siteId: props.siteId,
        },
      },
    },
  );

  return (
    <AnalyticsContext.Provider
      value={{
        analytics: analytics,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  return React.useContext(AnalyticsContext).analytics;
}
