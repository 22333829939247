import type {
  AssemblyNotification,
  AssemblyNotificationType,
  KycNotification,
  KycNotificationType,
  NewsNotification,
  Notification,
  RepaymentNotification,
  ReportingNotification,
  ReportingNotificationType,
  TaxResidenceNotification,
  WMANotification,
  WMANotificationType,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const isReportingNotification = (
  notification: Notification,
): notification is ReportingNotification => {
  return (
    [
      "reporting_to_validate",
      "reporting_validated",
      "reporting_alert",
    ] as ReportingNotificationType[]
  ).includes(notification.type as ReportingNotificationType);
};

export const isKycNotification = (
  notification: Notification,
): notification is KycNotification => {
  return (
    [
      "kyc_validated",
      "kyc_validated_investment_in_progress",
      "kyc_error",
    ] as KycNotificationType[]
  ).includes(notification.type as KycNotificationType);
};

export const isRepaymentNotification = (
  notification: Notification,
): notification is RepaymentNotification => {
  return notification.type === "repayment_received";
};

export const isNewsNotification = (
  notification: Notification,
): notification is NewsNotification => {
  return notification.type === "news_invest_created";
};

export const isWMANotification = (
  notification: Notification,
): notification is WMANotification => {
  return (
    [
      "wma_invitation_refused",
      "wma_invitation_accepted",
    ] as WMANotificationType[]
  ).includes(notification.type as WMANotificationType);
};

export const isAssemblyNotification = (
  notification: Notification,
): notification is AssemblyNotification => {
  return (
    [
      "assembly_convocation",
      "assembly_vote_reminder",
      "assembly_vote_request",
    ] as AssemblyNotificationType[]
  ).includes(notification.type as AssemblyNotificationType);
};

export const isTaxResidenceNotification = (
  notification: Notification,
): notification is TaxResidenceNotification => {
  return notification.type === "organization_address_to_update";
};
