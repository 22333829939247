import { useCallback } from "react";
import { UserIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import { login } from "../utils/login";

export function LoginButton({
  className,
  onLogin,
}: {
  className?: string;
  onLogin?: () => void;
}) {
  const handleLogin = useCallback(() => {
    if (onLogin) onLogin(); // Callback before redirecting
    login();
  }, [onLogin]);

  return (
    <>
      <SmallDeviceButton onClick={handleLogin} className={className} />
      <LargeDeviceButton onClick={handleLogin} className={className} />
    </>
  );
}

function SmallDeviceButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <ButtonRebrand
      size="lg"
      onClick={onClick}
      variant="secondary"
      className={cn(
        "body300-medium gap-x-2 !text-base text-neutral-900 lg:hidden",
        className,
      )}
    >
      {t("login")}
    </ButtonRebrand>
  );
}

function LargeDeviceButton({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <ButtonRebrand
      size="lg"
      onClick={onClick}
      variant="ghost"
      className={cn(
        "body300-medium group hidden gap-x-2 !text-base text-neutral-900 lg:flex",
        className,
      )}
    >
      <UserIcon
        strokeWidth={2.5}
        className="group-hover:text-accent h-5 min-h-4 w-5 min-w-4 text-neutral-900 transition-colors"
      />
      {t("login")}
    </ButtonRebrand>
  );
}
