import {
  useImpersonateId,
  useUserIdFromToken,
} from "@tudigo-monorepo/web-tudigo-auth-store";

export function useSessionUserId(): string {
  const connectedUserId = useUserIdFromToken();
  const impersonateId = useImpersonateId();

  return impersonateId ?? connectedUserId ?? "me";
}
