import {
  createContext,
  useContext,
  useMemo,
  type PropsWithChildren,
} from "react";

import { useComparatorSelection } from "./hooks/use-comparator-selection";
import { useComparatorUrlState } from "./hooks/use-comparator-url-state";
import type {
  ComparatorConfig,
  ComparatorSelection,
  ComparatorUrlState,
} from "./utils/type-defs";

type ComparatorContextValue<T> = {
  config: ComparatorConfig<T>;
  isLoading: boolean;
  resetComparator: () => void;

  canSelect: boolean;
  selection: ComparatorSelection<T>;
  selectionCount: number;
  setSelection: (selection: ComparatorSelection<T>) => void;
  selectItem: (item: T) => void;
  removeItem: (item: T) => void;
  isSelected: (item: T) => boolean;

  urlState: ComparatorUrlState;
  setUrlState: (
    subscribe:
      | ComparatorUrlState
      | ((state: ComparatorUrlState) => ComparatorUrlState),
  ) => void;
};

const ComparatorContext = createContext<ComparatorContextValue<any> | null>(
  null,
);

function useComparator<T>() {
  const context = useContext(ComparatorContext);

  if (context === null) {
    throw new Error("useComparator must be used within a ComparatorProvider");
  }

  return context as ComparatorContextValue<T>;
}

const DEFAULT_MIN_SELECTION = 2;
const DEFAULT_AMOUNT = 1000;

type ComparatorProviderProps<T> = Omit<
  ComparatorConfig<T>,
  "minSelection" | "defaulSimulatortAmount"
> & {
  minSelection?: number;
  defaultSimulatorAmount?: number;
  onUrlStateChange?: (state: ComparatorUrlState) => void;
};

function ComparatorProvider<T>({
  children,
  onUrlStateChange,
  ...configProps
}: PropsWithChildren<ComparatorProviderProps<T>>) {
  const config = useMemo<ComparatorConfig<T>>(
    () => ({
      ...configProps,
      minSelection: configProps.minSelection ?? DEFAULT_MIN_SELECTION,
      defaultSimulatorAmount:
        configProps.defaultSimulatorAmount ?? DEFAULT_AMOUNT,
    }),
    [configProps],
  );

  const { urlState, setUrlState } = useComparatorUrlState({
    config,
    onChange: (state) => {
      if (onUrlStateChange) {
        onUrlStateChange(state);
      }
    },
  });

  const selectionState = useComparatorSelection<T>({
    config,
    mode: urlState.mode,
    onChange: (state) => {
      if (onUrlStateChange) {
        onUrlStateChange(state);
      }
    },
  });

  const contextValue = useMemo<ComparatorContextValue<T>>(() => {
    return {
      ...selectionState,
      config,
      urlState,
      setUrlState,
      resetComparator: () => {
        selectionState.setSelection([]);
        setUrlState({
          mode: null,
          selection: null,
          amount: null,
        });
      },
    };
  }, [config, selectionState, urlState, setUrlState]);

  return (
    <ComparatorContext.Provider value={contextValue}>
      {children}
    </ComparatorContext.Provider>
  );
}

export { type ComparatorContextValue, useComparator, ComparatorProvider };
