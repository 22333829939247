import { InternalKycStatus, Organization } from "./models";

const mockOrganization: Organization = {
  id: "1",
  name: "Tudigo",
  website: "https://tudigo.co",
  logo: null,
  businessCategories: null,
  type: "company",
  members: [],
  socialLinks: {
    discord: "https://discord.gg/tudigo",
    facebook: "https://facebook.com/tudigo",
    instagram: "https://instagram.com/tudigo",
    linkedin: "https://linkedin.com/tudigo",
    tiktok: "https://tiktok.com/tudigo",
    x: "https://tudigo.co",
  },
  readyToSubscribe: true,
  internalKycStatus: InternalKycStatus.APPROVED,
  organizationRepresentative: {
    id: "1",
    isUltimateBeneficialOwner: true,
    isOrganizationRepresentative: true,
    firstName: "John",
    lastName: "Doe",
    email: "test@tst.com",
    phone: "0123456789",
    position: "CEO",
    birthDate: "1990-01-01",
    gender: "male",
    nationality: "FR",
    documents: [],
    user: null,
    birthLocation: {
      street1: null,
      street2: null,
      postalCode: null,
      city: "Paris",
      country: "FR",
      state: null,
      region: null,
      isVerified: true,
      email: null,
      firstName: null,
      lastName: null,
      id: null,
      name: null,
      phoneNumber: null,
      formatted: "",
    },
    sharesCount: 100,
    address: {
      street1: "1 rue de la Paix",
      street2: null,
      postalCode: "75000",
      city: "Paris",
      country: "FR",
      state: null,
      region: null,
      isVerified: true,
      email: null,
      firstName: null,
      lastName: null,
      id: null,
      name: null,
      phoneNumber: null,
      formatted: "",
    },
  },
  organizationBankAccounts: [],
  pmsAccount: null,
  companyInformation: {
    legalStatus: "SAS",
    capital: 10000,
    capitalInCents: 1000000,
    capitalCurrency: "EUR",
    registrationLocation: null,
    headOfficeAddress: null,
    registrationNumbers: {
      rcs: "123456789",
      siren: "123456789",
    },
  },
  individualInformation: null,
  persons: [
    {
      id: "1",
      isUltimateBeneficialOwner: true,
      isOrganizationRepresentative: true,
      firstName: "John",
      lastName: "Doe",
      email: "admin@test.com",
      phone: "0123456789",
      position: "CEO",
      sharesCount: null,
      documents: [],
      address: {
        street1: "1 rue de la Paix",
        street2: null,
        postalCode: "75000",
        city: "Paris",
        country: "FR",
        state: null,
        region: null,
        isVerified: true,
        email: null,
        firstName: null,
        lastName: null,
        id: null,
        name: null,
        phoneNumber: null,
        formatted: "",
      },
      birthDate: "1990-01-01",
      birthLocation: {
        street1: null,
        street2: null,
        postalCode: null,
        city: "Paris",
        country: "FR",
        state: null,
        region: null,
        isVerified: true,
        email: null,
        firstName: null,
        lastName: null,
        id: null,
        name: null,
        phoneNumber: null,
        formatted: "",
      },
      gender: "male",
      nationality: "FR",
      user: null,
    },
  ],
  documents: [],
  ultimateBeneficialOwners: [],
  equitySavingPlan: null,
  smallBusinessEquitySavingPlan: null,
};

export const mockOrganizations: Organization[] = [
  mockOrganization,
  {
    id: "2",
    name: "Org with PEA account",
    website: "",
    logo: null,
    type: "individual",
    socialLinks: {},
    readyToSubscribe: true,
    internalKycStatus: InternalKycStatus.APPROVED,
    businessCategories: null,
    organizationRepresentative: {
      id: "2",
      isUltimateBeneficialOwner: true,
      isOrganizationRepresentative: true,
      firstName: "John",
      lastName: "Doe",
      email: "test@tst.com",
      phone: "0123456789",
      position: "CEO",
      birthDate: "1990-01-01",
      gender: "male",
      nationality: "FR",
      documents: [],
      user: null,
      birthLocation: {
        street1: null,
        street2: null,
        postalCode: null,
        city: "Paris",
        country: "FR",
        state: null,
        region: null,
        isVerified: true,
        email: null,
        firstName: null,
        lastName: null,
        id: null,
        name: null,
        phoneNumber: null,
        formatted: "",
      },
      sharesCount: 100,
      address: {
        street1: "1 rue de la Paix",
        street2: null,
        postalCode: "75000",
        city: "Paris",
        country: "FR",
        state: null,
        region: null,
        isVerified: true,
        email: null,
        firstName: null,
        lastName: null,
        id: null,
        name: null,
        phoneNumber: null,
        formatted: "",
      },
    },
    organizationBankAccounts: [],
    pmsAccount: null,
    companyInformation: {
      legalStatus: "SAS",
      capital: 10000,
      capitalInCents: 1000000,
      capitalCurrency: "EUR",
      registrationLocation: null,
      headOfficeAddress: null,
      registrationNumbers: {
        rcs: "123456789",
        siren: "123456789",
      },
    },
    individualInformation: null,
    members: [],
    persons: [
      {
        id: "1",
        isUltimateBeneficialOwner: true,
        isOrganizationRepresentative: true,
        firstName: "John",
        lastName: "Doe",
        email: "admin@test.com",
        phone: "0123456789",
        position: "CEO",
        sharesCount: null,
        documents: [],
        user: null,
        address: {
          street1: "1 rue de la Paix",
          street2: null,
          postalCode: "75000",
          city: "Paris",
          country: "FR",
          state: null,
          region: null,
          isVerified: true,
          email: null,
          firstName: null,
          lastName: null,
          id: null,
          name: null,
          phoneNumber: null,
          formatted: "",
        },
        birthDate: "1990-01-01",
        birthLocation: {
          street1: null,
          street2: null,
          postalCode: null,
          city: "Paris",
          country: "FR",
          state: null,
          region: null,
          isVerified: true,
          email: null,
          firstName: null,
          lastName: null,
          id: null,
          name: null,
          phoneNumber: null,
          formatted: "",
        },
        gender: "male",
        nationality: "FR",
      },
    ],
    documents: [],
    ultimateBeneficialOwners: [],
    equitySavingPlan: {
      type: "pea",
      accountNumber: "123456789",
      iban: "FR123456789",
      bic: "123456789",
      invalidatedAt: null,
      bank: {
        id: "1",
        name: "Bank",
        online: true,
        templateCommitmentLetter: "",
        templateSubscriptionForm: "",
        agencies: [],
        allowsTudigo: true,
        minimumInvestmentAmountInCents: 150000,
        maximumInvestmentAmountInCents: 200000,
      },
      bankAgency: null,
      bankContactInformation: null,
    },
    smallBusinessEquitySavingPlan: null,
  },
];
