import { useCallback, useEffect, useRef, useState } from "react";
import { isEqual as isDeepEqual } from "lodash";

import { localeCompareSort } from "@tudigo-monorepo/core-tudigo-utils";

import type {
  ComparatorConfig,
  ComparatorMode,
  ComparatorSelection,
  ComparatorUrlState,
} from "../utils/type-defs";
import { useComparatorUrlState } from "./use-comparator-url-state";

export function useComparatorSelection<T>(params: {
  config: ComparatorConfig<T>;
  mode: ComparatorMode;
  onChange?: (state: ComparatorUrlState) => void;
}) {
  const { config, mode, onChange } = params;
  const { urlBuilderFn, useQueryFn, compareFn } = config;
  const { urlState, setUrlState } = useComparatorUrlState({ config, onChange });

  const selectionParams = urlState.selection;
  const [selection, setSelection] = useState<ComparatorSelection<T>>([]);

  const {
    data: response,
    isFetched,
    isFetching,
    refetch,
  } = useQueryFn(
    { filters: { slug: selectionParams ?? [] } },
    { enabled: false },
  );

  const isInit = useRef(true);

  const isStale =
    isInit.current === false &&
    !isDeepEqual(
      localeCompareSort(selection.map(urlBuilderFn)),
      localeCompareSort((response?.data ?? []).map(urlBuilderFn)),
    );

  useEffect(() => {
    if (mode !== "comparison") return;

    if (isInit.current === true && !isFetched) {
      refetch()
        .then(({ data: response }) => {
          setSelection(response?.data ?? []);
        })
        .then(() => {
          isInit.current = false;
        });
    }
  }, [mode, isFetched, refetch]);

  useEffect(() => {
    if (mode !== "comparison") return;

    if (isInit.current === false && isStale) {
      refetch().then(({ data: response }) => {
        setSelection(response?.data ?? []);
      });
    }
  }, [mode, isStale, refetch]);

  const selectItem = useCallback(
    (item: T) => {
      if (selectionParams) {
        setUrlState((prevState) => ({
          ...prevState,
          selection: [...new Set([...selectionParams, urlBuilderFn(item)])],
        }));
      } else {
        setUrlState((prevState) => ({
          ...prevState,
          selection: [urlBuilderFn(item)],
        }));
      }

      setSelection((prevSelection) => [...prevSelection, item]);
    },
    [selectionParams, setUrlState, urlBuilderFn],
  );

  const removeItem = useCallback(
    (item: T) => {
      if (selectionParams) {
        setUrlState((prevState) => ({
          ...prevState,
          selection: selectionParams.filter(
            (param) => urlBuilderFn(item) !== param,
          ),
        }));
      }

      setSelection((prevSelection) =>
        prevSelection.filter((selectedItem) => !compareFn(selectedItem, item)),
      );
    },
    [selectionParams, setUrlState, urlBuilderFn, compareFn],
  );

  const isSelected = useCallback(
    (item: T) =>
      selection.some((selectedItem) => compareFn(item, selectedItem)),
    [selection, compareFn],
  );

  const canSelect =
    mode === "selection" && selection.length < config.maxSelection;

  return {
    isLoading: isFetching,
    selection: selection,
    selectionCount: selection.length,
    canSelect,
    setSelection,
    selectItem,
    removeItem,
    isSelected,
  };
}
