import type { User } from "@tudigo-monorepo/core-tudigo-api-models";

const MIN_INVESTMENTS_COUNT = 2;
const CURRENT_AVERAGE_INVESTMENT_AMOUNT = 4_000;

export function getUserAverageInvestmentAmountCapacity(
  user: User | null,
  isQualified: boolean,
): number {
  if (user) {
    const totalInvestmentsAmountInCents =
      user?.investorProfile?.statistics?.totalInvestmentsAmountInCents;
    const totalInvestmentsCount =
      user?.investorProfile?.statistics?.totalInvestmentsCount;

    const hasAtLeastTwoInvestments =
      !!totalInvestmentsAmountInCents &&
      !!totalInvestmentsCount &&
      totalInvestmentsCount >= MIN_INVESTMENTS_COUNT;

    if (hasAtLeastTwoInvestments) {
      return totalInvestmentsAmountInCents / totalInvestmentsCount;
    }

    if (isQualified && user?.investorProfile?.annualInvestmentCapacity) {
      return user.investorProfile.annualInvestmentCapacity * 100;
    }
  }

  return CURRENT_AVERAGE_INVESTMENT_AMOUNT * 100;
}
