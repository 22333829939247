import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const badgeBase = "rounded text-xs h-[25px] flex items-center px-2";

const badgeRebrandVariants = cva(badgeBase, {
  variants: {
    variant: {
      default:
        "border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80",
      primary:
        "bg-accent-super-light-rebrand border border-accent-rebrand text-primary-rebrand",
      secondary: "bg-black-800 border border-black-500 text-black-900",
      tertiary: "bg-white border border-black-500 text-black-900",
      destructive:
        "border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
      outline: "text-foreground",
      success: "bg-success-light text-success-medium-rebrand",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface BadgeRebrandProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeRebrandVariants> {}

function BadgeRebrand({ className, variant, ...props }: BadgeRebrandProps) {
  return (
    <div
      className={cn(badgeRebrandVariants({ variant }), className)}
      {...props}
    />
  );
}

export { BadgeRebrand, badgeRebrandVariants };
