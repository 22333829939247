import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  NavigationMenu,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuList,
} from "@tudigo-monorepo/web-tudigo-components";

import { NavbarAuthBlock } from "../navbar-auth-block";
import { NavbarCardAbout } from "../navbar-cards/navbar-card-about";
import { NavbarCardFunds } from "../navbar-cards/navbar-card-funds";
import { NavbarCardInvest } from "../navbar-cards/navbar-card-invest";
import { NavbarCardResources } from "../navbar-cards/navbar-card-resources";
import { NavbarMenuAbout } from "../navbar-menus/navbar-menu-about";
import { NavbarMenuFunds } from "../navbar-menus/navbar-menu-funds";
import { NavbarMenuInvest } from "../navbar-menus/navbar-menu-invest";
import { NavbarMenuResources } from "../navbar-menus/navbar-menu-resources";
import { NavbarMenuTestimonials } from "../navbar-menus/navbar-menu-testimonials";
import { NavbarUserBlock } from "../navbar-user-block";
import { HorizontalMenuContent } from "./horizontal-menu-content";
import { HorizontalMenuTrigger } from "./horizontal-menu-trigger";

export const HorizontalMenu = () => {
  const { isConnected } = useUserBusiness();
  const { t } = useTranslation();

  return (
    <div className="hidden w-full items-center justify-between gap-x-16 lg:flex">
      <NavigationMenu
        delayDuration={200}
        className="w-full !max-w-none justify-between"
      >
        <NavigationMenuList className="justify-between">
          <div className="flex items-center justify-center">
            <NavigationMenuItem>
              <HorizontalMenuTrigger>
                {t("navbar.link.invest.label")}
              </HorizontalMenuTrigger>
              <HorizontalMenuContent>
                <NavbarMenuInvest />
                <NavbarCardInvest />
              </HorizontalMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <HorizontalMenuTrigger>
                {t("navbar.link.funds.label")}
              </HorizontalMenuTrigger>
              <HorizontalMenuContent>
                <NavbarMenuFunds />
                <NavbarCardFunds />
              </HorizontalMenuContent>
            </NavigationMenuItem>
          </div>

          <div className="flex items-center justify-center">
            <NavigationMenuItem className="ml-auto">
              <HorizontalMenuTrigger>
                {t("navbar.link.testimonials.label")}
              </HorizontalMenuTrigger>
              <HorizontalMenuContent>
                <NavbarMenuTestimonials />
              </HorizontalMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <HorizontalMenuTrigger>
                {t("navbar.link.about.label")}
              </HorizontalMenuTrigger>
              <HorizontalMenuContent>
                <NavbarMenuAbout />
                <NavbarCardAbout />
              </HorizontalMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <HorizontalMenuTrigger>
                {t("navbar.link.resources.label")}
              </HorizontalMenuTrigger>
              <HorizontalMenuContent>
                <NavbarMenuResources />
                <NavbarCardResources />
              </HorizontalMenuContent>
            </NavigationMenuItem>
          </div>

          <NavigationMenuIndicator className="bg-primary-rebrand h-0.5" />
        </NavigationMenuList>
      </NavigationMenu>

      {isConnected ? <NavbarUserBlock /> : <NavbarAuthBlock />}
    </div>
  );
};
