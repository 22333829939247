import { useMemo, type PropsWithChildren } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderCircleIcon } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import type { TaxExemptionSituation } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { CityCountrySelector } from "@tudigo-monorepo/web-feature-form";
import {
  ButtonRebrand,
  CountrySelectInputConnected,
  DateInputConnected,
  SelectInputConnected,
  TextInputConnected,
} from "@tudigo-monorepo/web-tudigo-components";

import { isValidCSGTaxExemptionData } from "../../../utils/csg-tax-exemption-utils";

const SITUATION_OPTIONS: TaxExemptionSituation[] = [
  "employed_worker",
  "non_employed_worker",
  "pensioner_or_annuitant",
  "other",
];

const formSchema = z.object({
  since: z
    .date()
    .refine((val) => val <= new Date(), {
      message: "Veuillez renseigner une date antérieure à la date actuelle",
    })
    .nullable(),
  state: z
    .string()
    .min(2, { message: "Veuillez renseigner un pays" })
    .nullable(),
  socialProtectionFound: z
    .string()
    .min(1, { message: "Veuillez renseigner une caisse de protection sociale" })
    .nullable(),
  situation: z
    .union([
      z.custom<TaxExemptionSituation>().default(SITUATION_OPTIONS[0]),
      z.custom<TaxExemptionSituation>().default(SITUATION_OPTIONS[1]),
      z.custom<TaxExemptionSituation>().default(SITUATION_OPTIONS[2]),
      z.custom<TaxExemptionSituation>().default(SITUATION_OPTIONS[3]),
    ])
    .default(SITUATION_OPTIONS[0])
    .nullable(),
  city: z
    .string()
    .min(1, { message: "Veuillez renseigner une ville" })
    .nullable(),
});

type CSGTaxExemptionFormData = z.infer<typeof formSchema>;

type CSGTaxExemptionFormProps = {
  onSubmit: (data: CSGTaxExemptionFormData) => void;
  className?: string;
  defaultValues?: CSGTaxExemptionFormData;
  isLoading?: boolean;
};

function CSGTaxExemptionForm({
  onSubmit,
  className,
  defaultValues,
  isLoading = false,
}: PropsWithChildren<CSGTaxExemptionFormProps>) {
  const { t } = useTranslation();

  const form = useForm<CSGTaxExemptionFormData>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const formValues = form.watch();

  const isValidForm = useMemo(() => {
    return (
      form.formState.isValid ||
      (isValidCSGTaxExemptionData({
        ...formValues,
        since: formValues.since?.toString() ?? null,
      }) &&
        formValues.city !== null)
    );
  }, [form.formState.isValid, formValues, isValidCSGTaxExemptionData]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-4"
      >
        <div className="flex flex-col gap-y-4 rounded-lg border border-neutral-200 bg-neutral-100 p-4">
          <div
            className={cn("flex w-full flex-col gap-y-6 lg:w-2/3", className)}
          >
            <DateInputConnected
              required
              name="since"
              label={t(
                "tax.tax-page.tax-exemptions.csg-form.dialog.form-field.since.label",
              )}
            />
            <CountrySelectInputConnected
              required
              name="state"
              label={t(
                "tax.tax-page.tax-exemptions.csg-form.dialog.form-field.state.label",
              )}
            />
            <TextInputConnected
              required
              name="socialProtectionFound"
              label={t(
                "tax.tax-page.tax-exemptions.csg-form.dialog.form-field.social-protection-found.label",
              )}
              placeholder="Lorem ipsum"
            />
            <SelectInputConnected
              required
              name="situation"
              label={t(
                "tax.tax-page.tax-exemptions.csg-form.dialog.form-field.situation.label",
              )}
              options={SITUATION_OPTIONS.map((situation) => ({
                value: situation,
                label: t(
                  `tax.tax-page.tax-exemptions.csg-form.dialog.form-field.situation.option.${situation}`,
                ),
              }))}
            />
            <CityCountrySelector
              required
              label={t(
                "tax.tax-page.tax-exemptions.csg-form.dialog.form-field.city.label",
              )}
              className="[&>label>p]:body300-regular [&>label>p]:text-dark-1 space-y-2"
              onCityCountrySelected={(location) => {
                if (location.city) {
                  form.setValue("city", location.city);
                }
              }}
            />
          </div>
        </div>

        <ButtonRebrand
          type="submit"
          variant="accent"
          className="ml-auto"
          disabled={!isValidForm || isLoading}
        >
          {t("tax.tax-page.tax-exemptions.csg-form.dialog.confirm")}
          {isLoading && (
            <LoaderCircleIcon className="ml-2 h-5 w-5 animate-spin text-white" />
          )}
        </ButtonRebrand>
      </form>
    </FormProvider>
  );
}

export { type CSGTaxExemptionFormData, CSGTaxExemptionForm };
