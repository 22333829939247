import type { ReactNode } from "react";
import { CheckIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  login,
  signup,
  storeCallbackRedirectUrl,
} from "@tudigo-monorepo/web-feature-login";
import {
  ButtonRebrand,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  TrustpilotMicroStarWidget,
} from "@tudigo-monorepo/web-tudigo-components";

import { useRestrictedAccess } from "../hooks/use-restricted-access";

type RestrictedAccessProps = {
  children?: ReactNode;
  className?: string;
  openAsDialog?: boolean;
  defaultOpen?: boolean;
  gradientSeparator?: boolean;
  onClose?: (open: boolean) => void;
};

export function RestrictedAccess({
  openAsDialog = false,
  ...props
}: RestrictedAccessProps) {
  const { restricted } = useRestrictedAccess();

  if (!restricted) return props.children;

  return openAsDialog ? (
    <RestrictedAccessDialog {...props} />
  ) : (
    <RestrictedAccessCard {...props} />
  );
}

function RestrictedAccessDialog({
  children,
  defaultOpen = false,
  onClose,
  ...props
}: Omit<RestrictedAccessProps, "openAsDialog">) {
  return (
    <Dialog defaultOpen={defaultOpen} onOpenChange={onClose}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="block w-fit border-none bg-transparent p-0 shadow-none">
        <DialogTitle className="hidden" />
        <RestrictedAccessCard {...props} />
      </DialogContent>
    </Dialog>
  );
}

function RestrictedAccessCard({
  children,
  className,
  gradientSeparator = false,
}: Omit<RestrictedAccessProps, "openAsDialog">) {
  const { restricted, reason } = useRestrictedAccess();

  if (!restricted) return null;

  return (
    <article
      className={cn(
        "border-light-1 text-primary relative rounded-lg border bg-white p-8",
        className,
      )}
    >
      {children}

      {gradientSeparator && (
        <div className="w-[calc(100% + 40px)] pointer-events-none absolute -left-5 -right-5 top-[-102px] h-[100px] bg-gradient-to-t from-white to-transparent" />
      )}

      {reason === "unauthenticated" && <AuthenticateContent />}
      {reason === "incomplete-profile" && <CompleteProfileContent />}

      <footer className="border-light-1 flex justify-end border-t pt-6">
        <TrustpilotMicroStarWidget />
      </footer>
    </article>
  );
}

function AuthenticateContent() {
  return (
    <>
      <h1 className="body500-bold mb-2 text-black">Inscription / Connexion</h1>
      <AdvantageList />
      <div className="mb-6 flex gap-3">
        <ButtonRebrand onClick={signup}>{`S'inscrire`}</ButtonRebrand>
        <ButtonRebrand variant="outline" onClick={login}>
          Se connecter
        </ButtonRebrand>
      </div>
    </>
  );
}

function CompleteProfileContent() {
  return (
    <>
      <h1 className="body500-bold mb-2 text-black">Complétez votre profil</h1>
      <AdvantageList />
      <div className="mb-6 flex gap-3">
        <Link to="/qualification">
          <ButtonRebrand onClick={storeCallbackRedirectUrl}>
            Compléter mon profil
          </ButtonRebrand>
        </Link>
      </div>
    </>
  );
}

function AdvantageList() {
  return (
    <>
      <p className="body300-regular mb-6 text-black">
        Les informations détaillées ne peuvent être communiquées qu’aux
        personnes ayant complété leur profil investisseur
      </p>
      <ul className="mb-6 space-y-3 text-sm font-medium text-gray-900">
        <AdvantageItem>Découvrir en détail les opportunités</AdvantageItem>
        <AdvantageItem>Investir dans une startup à impact</AdvantageItem>
        <AdvantageItem>Rejoindre une communauté dynamique</AdvantageItem>
        <AdvantageItem>Faire fructifier votre épargne</AdvantageItem>
      </ul>
    </>
  );
}

function AdvantageItem({ children }: { children: ReactNode }) {
  return (
    <li className="flex items-center gap-3">
      <span className="bg-success-light grid h-8 w-8 place-items-center rounded-full">
        <CheckIcon className="text-success" size={16} strokeWidth={4} />
      </span>
      {children}
    </li>
  );
}
