"use client";

import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close
    ref={ref}
    className={cn(
      `
      ring-offset-background focus:ring-ring
      data-[state=open]:bg-accent data-[state=open]:text-muted-foreground
       flex items-center justify-center
      rounded-sm opacity-70 transition-opacity hover:opacity-100
      focus:outline-none focus:ring-offset-2 focus-visible:ring-2 disabled:pointer-events-none`,
      className,
    )}
    {...props}
  >
    <X className="h-6 w-6" />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>
));

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "z-dialog-overlay",
      `
      data-[state=open]:animate-in
      data-[state=closed]:animate-out
      data-[state=closed]:fade-out-0
      data-[state=open]:fade-in-0
      fixed inset-0 bg-black/50
      backdrop-blur-[5px]
      `,
      className,
    )}
    {...props}
  />
));

const DialogSeparator = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) => (
  <div
    className={cn(
      "border-light-2 -mx-8 w-[calc(100%+4rem)] border-t",
      className,
    )}
    {...props}
  />
);

const dialogContentClasses = cn(
  "z-dialog",
  `
        data-[state=open]:animate-in
        data-[state=closed]:animate-out data-[state=closed]:fade-out-0
        data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95
        data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2
        data-[state=closed]:slide-out-to-top-[48%]
        data-[state=open]:slide-in-from-left-1/2
        data-[state=open]:slide-in-from-top-[48%]
        fixed left-[50%]
        top-[50%] grid max-h-screen
        w-full max-w-4xl translate-x-[-50%]
        translate-y-[-50%] overflow-auto border bg-white
        p-8 shadow-lg
        duration-200
        sm:rounded-xl`,
);

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    hasCloseButton?: boolean;
  }
>(({ className, children, hasCloseButton = true, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay className="overflow-auto">
      <DialogPrimitive.Content
        ref={ref}
        aria-describedby={undefined}
        className={cn(dialogContentClasses, className)}
        {...props}
      >
        {children}

        {hasCloseButton && <DialogClose className="absolute right-6 top-8" />}
      </DialogPrimitive.Content>
    </DialogOverlay>
  </DialogPortal>
));

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse pt-6 sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      `
      pb flex items-center pb-6
      text-lg font-semibold leading-none tracking-tight`,
      className,
    )}
    {...props}
  />
));

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-muted-foreground text-sm", className)}
    {...props}
  />
));

const DialogItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={cn("text-muted-foreground text-sm", className)}>
    {children}
  </div>
);

export {
  Dialog,
  DialogSeparator,
  DialogPortal,
  DialogItem,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
