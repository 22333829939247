import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const alertRebrandVariants = cva(
  `
  relative w-full rounded-md border p-6
  font-roobert text-neutral-900
  `,
  {
    variants: {
      variant: {
        default: "bg-white border-black-600 [&_svg]:text-black",
        error: "bg-error-100 border-error-200 [&_svg]:text-error-500",
        success: "bg-success-100 border-success-200 [&_svg]:text-success-500",
        info: "bg-primary-50 border-primary-200 [&_svg]:text-primary-500",
        warning: "bg-warning-100 border-warning-500 [&_svg]:text-warning-500",
        loading: "bg-waiting-100 border-waiting-500 [&_svg]:text-waiting-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

type AlertRebrandVariant = VariantProps<typeof alertRebrandVariants>;

const AlertRebrand = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & AlertRebrandVariant
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertRebrandVariants({ variant }), className)}
    {...props}
  />
));

const AlertRebrandTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "body400-medium mb-2 flex items-center gap-2 text-balance leading-none tracking-tight text-black",
      className,
    )}
    {...props}
  />
));

const AlertRebrandDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("body200-regular [&_p]:leading-relaxed", className)}
    {...props}
  />
));

export {
  AlertRebrand,
  AlertRebrandTitle,
  AlertRebrandDescription,
  type AlertRebrandVariant,
};
