import { Bank, UpdateBankBody } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const banksEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getBanks
   * @summary get banks
   * @request GET:/api/banks
   * @secure
   */
  getBanks: (query?: { [key: string]: unknown }) =>
    request<TudigoResponse<Bank[]>, void>({
      path: `/api/v1/banks`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
    }),

  getBankById: ({
    bankId,
    query,
    params,
  }: {
    bankId: string;
    query?: Record<string, string>;
    params?: RequestParams;
  }) => {
    return request<TudigoResponse<Bank>, void>({
      path: `/api/v1/banks/${bankId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  },

  updateBank: (
    bankId: Bank["id"],
    body: UpdateBankBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Bank>, void>({
      path: `/api/v1/banks/${bankId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
