import { z } from "zod";

const formSchema = z
  .object({
    id: z.string().optional(),
    title: z.string().min(1, {
      message: "back_office.edit_project.project_news.form_error.title",
    }),
    type: z.enum(["information", "document", "video"]).default("information"),
    description: z.string().optional().nullable(),
    shouldSendMail: z.boolean().optional().default(false),
    shouldSendNotification: z.boolean().optional().default(false),
    document: z
      .array(
        z.object({
          base64: z.string().optional(),
          originalFilename: z.string().optional(),
          id: z.string().optional(),
          url: z.string().optional(),
          signedUrl: z.string().optional(),
          mimeTypeExtension: z.string().optional(),
        }),
      )
      .max(1)
      .optional()
      .nullable(),
    video: z
      .object({
        title: z.string(),
        url: z.string(),
      })
      .optional()
      .nullable(),
    publishedAt: z.date().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.type === "information" && !data.description) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["description"],
        message: "back_office.edit_project.project_news.form_error.description",
      });
    }

    if (data.type === "document" && !data.document?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["document"],
        message: "back_office.edit_project.project_news.form_error.document",
      });
    }

    if (data.type === "video" && !data.video?.title?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["video", "title"],
        message: "back_office.edit_project.project_news.form_error.video.title",
      });
    }

    if (data.type === "video" && !data.video?.url?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["video", "url"],
        message: "back_office.edit_project.project_news.form_error.video.url",
      });
    }
  });

type ProjectNewsFormValues = z.infer<typeof formSchema>;

const defaultProjectNewsFormValues: ProjectNewsFormValues = {
  id: undefined,
  type: "information",
  title: "",
  description: undefined,
  document: undefined,
  video: { title: "", url: "" },
  shouldSendMail: false,
  shouldSendNotification: false,
  publishedAt: undefined,
};

export { formSchema, defaultProjectNewsFormValues, type ProjectNewsFormValues };
