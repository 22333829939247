import { useCallback, useDeferredValue, useState } from "react";
import * as React from "react";

import {
  Bank,
  bankShape,
  type BankShape,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useGetBanksQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Button,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

const LIMIT = 1000;
const banksQueryFields =
  "id,name,online,template_commitment_letter,template_subscription_form,agencies.fields(id,name,address,phone_number,email),allows_tudigo,minimum_investment_amount_in_cents,maximum_investment_amount_in_cents";

type BankSelectorProps = {
  selectedBank: Bank | BankShape | null;
  label?: string;
  contentClassName?: string;
  placeholder?: string;
  required?: boolean;
  allBanksOption?: boolean;
  onBankChange?: (item: Bank | BankShape) => void;
};

export function BankSelector({
  selectedBank,
  label,
  contentClassName,
  placeholder,
  required = false,
  allBanksOption = false,
  onBankChange,
}: BankSelectorProps) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchFilter = useDeferredValue(searchValue);

  const { data: banks, isLoading: isLoadingBanks } = useGetBanksQuery({
    fields: banksQueryFields,
    filters: { term: searchFilter },
    pagination: { limit: LIMIT },
  });

  const banksResponse = banks?.data ?? [];

  const handleBankChange = (item: Bank | BankShape) => {
    setIsSearchOpen(false);
    onBankChange?.(item);
  };

  const getPlaceholder = useCallback(() => {
    if (selectedBank) {
      return selectedBank.name;
    }

    if (placeholder) {
      return placeholder;
    }

    return "Rechercher une banque";
  }, [selectedBank, placeholder]);

  const getFilteredBanks = () => {
    let filteredBanks: (Bank | BankShape)[] = [...banksResponse];

    if (allBanksOption) {
      filteredBanks = [
        { ...bankShape, name: "Tous les établissements bancaires" },
        ...banksResponse,
      ];
    }

    return filteredBanks.filter((bank) => bank.name.includes(searchValue));
  };

  return (
    <Popover open={isSearchOpen} onOpenChange={setIsSearchOpen}>
      <Label>
        {label ?? "Banque"}
        {required && <span className="text-destructive pl-0.5">*</span>}
      </Label>

      <PopoverTrigger asChild>
        <Button
          variant="tertiary"
          aria-expanded={isSearchOpen}
          className="border-dark-2 flex w-full !justify-between rounded-lg"
          iconRight="ChevronDown"
          label={getPlaceholder()}
          labelClassName="font-montserrat text-dark-3 text-[13px] font-normal"
        />
      </PopoverTrigger>
      <PopoverContent className={cn("w-full p-0", contentClassName)}>
        <Command shouldFilter={false}>
          <CommandInput
            value={searchValue}
            onValueChange={(search) => setSearchValue(search)}
            placeholder="Rechercher par nom"
          />
          <CommandList className="w-full">
            <CommandEmpty>Aucune banque trouvé</CommandEmpty>
            {isLoadingBanks ? (
              <CommandLoading className="flex justify-center p-4">
                Chargement...
              </CommandLoading>
            ) : (
              getFilteredBanks().map((bank, index) => (
                <CommandItem
                  key={bank.id ?? index}
                  value={bank.id ?? ""}
                  onSelect={() => handleBankChange(bank)}
                  className="px-4 py-3"
                >
                  {bank.name}
                </CommandItem>
              ))
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
