import {
  TaxExemption,
  TaxExemptionName,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export type CreateUserTaxExemptionBody = {
  taxName: TaxExemptionName;
  validityStartDate: string;
  validityEndDate: string;
  since?: string;
  state?: string;
  socialProtectionFound?: string;
  situation?: string;
};

export type UpdateTaxExemptionBody = Pick<
  TaxExemption,
  "since" | "state" | "socialProtectionFound" | "situation"
>;

export const userTaxExemptionsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getUserTaxExemptions
   * @summary get user tax exemptions
   * @request GET:/api/v1/users/{userId}/tax-exemptions
   * @secure
   */
  getUserTaxExemptions: (
    userId: User["id"] | "me",
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<TaxExemption[]>, void>({
      path: `/api/v1/users/${userId}/tax-exemptions`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name createUserTaxExemption
   * @summary create user tax exemption
   * @request POST:/api/v1/users/{userId}/tax-exemptions
   * @secure
   */
  createUserTaxExemption: (
    userId: User["id"] | "me",
    body: CreateUserTaxExemptionBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<TaxExemption>, void>({
      path: `/api/v1/users/${userId}/tax-exemptions`,
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateTaxExemption: (
    taxExemptionId: string,
    body: UpdateTaxExemptionBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<TaxExemption>, void>({
      path: `/api/v1/tax-exemptions/${taxExemptionId}`,
      method: "PUT",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
