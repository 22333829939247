import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { Category } from "./footer-data-rebrand";
import { FooterLink } from "./footer-link";

export function FooterCategoryRebrandMobile({
  category,
}: {
  category: Category;
}) {
  return (
    <details className="links-bloc group mb-2 transition-transform ">
      <summary className="flex items-center justify-between rounded-md bg-white px-2.5 py-2.5 font-medium">
        {category.title}
        <Icon
          className="rotate-45 transition-transform duration-300 ease-in-out group-open:rotate-90"
          name="Cross"
          primaryColor={themeColors["black-primary"]}
          size="XXS"
        />
      </summary>
      <ul className="flex flex-col gap-2 px-2.5 py-2.5">
        {category.links.map((link, linkIndex) => (
          <li key={linkIndex}>
            <FooterLink
              link={link}
              className="text-black-900 hover:text-primary-rebrand flex items-center gap-2 font-normal transition-colors duration-300"
            />
          </li>
        ))}
      </ul>
    </details>
  );
}
